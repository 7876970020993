import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";

import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import moment from "moment";

import { deleteGoal } from "../actions";
import { SetGoalsButtons } from "./setGoalsButtons";
import { Box, Switch, Button } from "@material-ui/core";
import { DefaultButton } from "./defaultButton";

import { setAsCurrentOffer, deleteOffer } from "../actions";

const dateFormat = "YYYY-MM-DD";

const OfferSwitchItem = (props) => {
  const { k, value } = props;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography>{k}</Typography>
      <Switch checked={value === true} />
    </Box>
  );
};
const OfferCard = (props) => {
  const { offer, setAsCurrentOffer, deleteOffer, isCurrent } = props;
  const {
    title,
    subtitle,
    content,
    actionButtonTitle,
    hasTrialPeriod,
    trialPeriodTitle,
    actionButtonPrice,
    isActive,
    isFirstTimeUserOffer,
    deadlineDate,
  } = offer;

  const isItRunningRightNow =
    isActive && (isFirstTimeUserOffer || moment().isBefore(deadlineDate));
  return (
    <Card
      style={{
        width: 300,
        marginRight: 10,
        marginBottom: 10,
        direction: "rtl",
      }}
    >
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography color="textPrimary">{title}</Typography>

              <Typography
                color="textPrimary"
                variant="subtitle1"
                style={{ color: "grey" }}
              >
                {subtitle}
              </Typography>
            </Box>
            <Box
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: isItRunningRightNow ? "green" : "red",
              }}
            />
          </Box>
          <Divider style={{ marginBottom: 10, marginTop: 10 }} />
          <Typography
            color="textPrimary"
            variant="subtitle2"
            style={{ color: "black" }}
          >
            {content}
          </Typography>

          <Box
            style={{
              border: "0.5px solid grey",
              borderRadius: 5,
              padding: 5,
              margin: 10,
            }}
          >
            <Typography
              color="textPrimary"
              variant="subtitle2"
              style={{ color: "black" }}
            >
              {`${actionButtonTitle} - ${actionButtonPrice}`}
            </Typography>
          </Box>
          {hasTrialPeriod && (
            <Typography variant="subtitle2" color="textSecondary">
              {trialPeriodTitle}
            </Typography>
          )}
          <Divider style={{ marginBottom: 10, marginTop: 10 }} />
          {[
            "isActive",
            "hasTrialPeriod",
            "showCountDownTimer",
            "isFirstTimeUserOffer",
          ].map((key) => (
            <OfferSwitchItem k={key} value={offer[key]} />
          ))}
          <Typography
            color="textPrimary"
            variant="subtitle2"
            style={{ color: "black" }}
          >
            deadline - {deadlineDate}
          </Typography>
          <Divider style={{ marginBottom: 10, marginTop: 10 }} />
          <DefaultButton
            onClick={() => {
              setAsCurrentOffer(offer);
            }}
          >
            Edit Offer
          </DefaultButton>
          {isCurrent === false && (
            <Button
              onClick={() => {
                deleteOffer(offer);
              }}
              color="secondary"
            >
              Delete Offer
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state, props) => ({});

const mapDispatchToProps = (dispatch) => ({
  setAsCurrentOffer: (offer) => setAsCurrentOffer(dispatch, offer),
  deleteOffer: (offer) => deleteOffer(dispatch, offer),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferCard);
