import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TextField } from "@material-ui/core";

class UpdateGoalsButton extends React.Component {
  state = {
    open: false
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = name => event => {
      
      this[name] = event.target.value
  };

  render() {
    const {
      okButtonHandler,
      buttonTitle,
      title,
      deadlineDateDefaultValue,
      targetDefaultValue
    } = this.props;

    return (
      <div style={{marginBottom: 5}}>
        <Button
          color="primary"
          variant="contained"
          onClick={this.handleClickOpen}
        >
          {buttonTitle}
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <TextField
              onChange={this.handleChange("goalDeadline") }
              id="date"
              label="Birthday"
              type="date"
              defaultValue={deadlineDateDefaultValue}
              InputLabelProps={{
                shrink: true
              }}
              style={{ marginLeft: 20 }}
            />
            <TextField
              onChange={this.handleChange("goalTargetNumber") }
              required
              id="goalsNumber"
              label="Required"
              defaultValue={targetDefaultValue}
              margin="normal"
              variant="filled"
              InputLabelProps={{
                shrink: true
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" autoFocus>
              الغاء
            </Button>
            <Button
              onClick={() => {
                
                const goalDeadline = this.goalDeadline || this.props.deadlineDateDefaultValue
                const goalTargetNumber = this.goalTargetNumber || this.props.targetDefaultValue
                okButtonHandler({goalDeadline, goalTargetNumber});
                this.handleClose();
              }}
              color="primary"
            >
              تم
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UpdateGoalsButton;
