import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export const DefaultButton = (props) => (
  <Button
    variant="outlined"
    color="primary"
    size="small"
    
    style={{ marginRight: 10, marginBottom: 5 }}
    {...props}
  />
);
