import React from "react";
import UpdateGoalsButton from "./updateGoalButton";
import ButtonWithAlertDialog from "./buttonWithAlertDialog";
export const SetGoalsButtons = ({ deadline, target, ...props }) => {

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <UpdateGoalsButton
        deadlineDateDefaultValue={deadline}
        targetDefaultValue={target}
        buttonTitle="تعديل الهدف"
        okButtonHandler={(data) => {
          const id = props.id;

          props.onUpdateGoalPress({ id, ...data });
        }} />
      <ButtonWithAlertDialog
        okButtonTitle="اه"
        cancelButtonTitle="لا"
        buttonTitle="حذف الهدف"
        title=" ؟ متاكد يا مدحت"
        okButtonHandler={() => {
          props.deleteGoal(props.id);
        }} />
    </div>
  );
};
