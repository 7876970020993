import React, { Component, useState, useEffect } from "react";
import styled from "styled-components";

import { Canvas, Image } from "canvas";
import mergeImages from "merge-images";
import Axios from "axios";

export const Container = ({ className, ...props }) => {
  let direction = props.isRow == true ? "row" : "column";
  let isCenter = props.isCenter == true ? "0 auto" : "0";

  return (
    <div
      className={className}
      style={{
        ...{
          direction: "rtl",
          textAlign: "right",
          display: "flex",
          flexWrap: "wrap",
          margin: isCenter,
          flexDirection: direction,
        },
        ...props.style,
        ...props,
      }}
    >
      {props.children}
    </div>
  );
};

export const LineSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #707070;
  margin-top: ${(props) => props.margin || 30}px;
  margin-bottom: ${(props) => props.margin || 30}px;
`;

const SummaryTitle = styled.h2`
  font-family: Cairo, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin-bottom: 10px;
`;
const SummarySubTitle = styled.p`
  font-family: Cairo, sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: black;
  margin: 0;
  margin-bottom: 5px;
`;

const SummaryAuthor = styled(SummarySubTitle)`
  color: grey;
  font-size: 12px;
`;

const SummaryIntro = styled.p`
  font-family: Cairo, sans-serif;
  font-size: 16px;
  line-height: 2;
  font-weight: 400;
  color: #707070;
`;

const ArticleSummaryView = ({ summary, index }) => {
  return (
    <Container>
      <SummaryTitle>{`${index + 1}- ${summary.title}`}</SummaryTitle>
      <img
        src={summary.imageURL}
        alt={"كتاب " + summary.title}
        width="154"
        height="237"
      />

      <SummarySubTitle>{summary.subtitle}</SummarySubTitle>
      <SummaryAuthor>{summary.authorRef.title}</SummaryAuthor>

      <LineSeparator />
      <SummaryIntro>{summary.summaryDescription}</SummaryIntro>
      <br />
      <br />
      {summary.highlights && <strong>بعض المقتطفات من الملخص</strong>}

      {summary.highlights &&
        summary.highlights.slice(-3).map((highlight) => (
          <blockquote class="wp-block-quote">
            <p>{highlight.text}</p>
          </blockquote>
        ))}
      <a href={"https://www.engzketab.com/" + "كتاب " + summary.title}>
        اقرأ ملخص الكتاب
      </a>

      <hr class="wp-block-separator" />

      <br />
      <br />
      <br />
    </Container>
  );
};

const getURLParameter = (name, fullURL) => {
  return (
    decodeURIComponent(
      (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
        fullURL
      ) || [null, ""])[1].replace(/\+/g, "%20")
    ) || null
  );
};

export default (props) => {
  const [summaries, setSummaries] = useState([]);
  const [base64String, setBase64String] = useState("");

  useEffect(() => {
    let fullURL = window.location.href;

    let doShowHighlights = getURLParameter("showHighlights", fullURL);

    let summariesIdsSeparatedByComma = getURLParameter("summariesIds", fullURL);
    let summariesIds = summariesIdsSeparatedByComma.split(",");

    Axios.request({
      url: "https://parseapi.back4app.com/classes/SummaryNew",
      headers: {
        "X-Parse-Application-Id": "gf7n6c97NwulXi9BbD1XrgFcBEBu4FP1m2DDVCGQ",
        "X-Parse-REST-API-Key": "JAPISWJQ3OOkwCKTrIBtIkg8ydoy5I2IXpigLikT",
      },
      params: {
        where: { objectId: { $in: summariesIds } },
      },
    })
      .then((response) => response.data.results)
      .then((summaries) => {
        //alert(JSON.stringify(summaries) )

        let photosURLs = summaries.map((summary, index) => {
          // var image = new Image()
          // image.url = summary.imageURL
          // image.width = 200
          // return image
          return {
            src: summary.imageURL,
            x: 200 * index,
            y: 0,
            width: 100,
          };
        });

        mergeImages(photosURLs, {
          Canvas: Canvas,
          Image: Image,
          crossOrigin: "Anonymous",
          width: 1000,
        }).then((b64) => setBase64String(b64));

        if (doShowHighlights) {
          Axios.request({
            url: "https://parseapi.back4app.com/classes/Highlight",
            headers: {
              "X-Parse-Application-Id":
                "gf7n6c97NwulXi9BbD1XrgFcBEBu4FP1m2DDVCGQ",
              "X-Parse-REST-API-Key":
                "JAPISWJQ3OOkwCKTrIBtIkg8ydoy5I2IXpigLikT",
            },
            params: {
              where: { summaryRef: { $in: summariesIds } },
            },
          })
            .then((response) => response.data.results)
            .then((highlights) => {
              const summariesWithHighlights = summaries.map((summary) => {
                const summaryHighlights = highlights.filter(
                  (highlight) =>
                    highlight.summaryRef.objectId == summary.objectId
                );
                return {
                  ...summary,
                  highlights: summaryHighlights,
                };
              });

              setSummaries(summariesWithHighlights);
            });
        } else {
          setSummaries(summaries);
        }
      });
  }, []);

  return (
    <div>
      <Container>
        <Container
          isCenter={true}
          style={{
            width: "100%",
            maxWidth: "900px",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <Container
            isCenter={true}
            style={{ width: "100%", maxWidth: "900px", padding: 40 }}
          >
            <img src={`data:image/png;${base64String}`} alt="" />
            <p>
              {`نستعرض في هذا المقال "افضل كتب التاريخ الاسلامي" مصحوبة بنبذة مبسطة عن كل كتاب، و امكانية قراءة ملخص كل كتاب اذا اردت معرفة المزيد عن محتواه. 

حرصنا علي تنوع الكتب التي تتناول "التاريخ الاسلامي" و اختيار كتب قد قرأناها بالفعل و قمنا بتلخيصها من قبل، لذلك هذه الترشيحات تمت بناءاً علي قراءة متأنية و مراجعة من فريق التحرير الخاص بالمدونة. وفي هذه المقال نستعرض لكم كتب مثل "${
                summaries.length > 0 ? summaries[0].title : ""
              }"، "${summaries.length > 1 ? summaries[1].title : ""}" و غيرها.`}
            </p>
            <hr class="wp-block-separator" />
            {summaries.map((summary, index) => (
              <ArticleSummaryView summary={summary} index={index} />
            ))}
          </Container>
        </Container>
      </Container>
    </div>
  );
};
