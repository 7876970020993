import Parse from "./app";
import { getTopics, getCategories, getCategory } from "./meta";

import { random } from "node-forge";

const MetaClass = Parse.Object.extend("SummaryMeta");
const SummaryClass = Parse.Object.extend("SummaryNew");

export const getSummariesCountForTopic = async (topicId) => {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));

  const topicObject = new MetaClass();
  topicObject.id = topicId;
  query.equalTo("topicsRef", topicObject);

  return await query.count();
};

export const getSummariesFirstPositionCountForTopic = async (topicId) => {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));

  const topicObject = new MetaClass();
  topicObject.id = topicId;
  query.equalTo("topicsRef", topicObject);

  query.select("topicsRef");
  query.include("topicsRef");

  let results = await query.find();

  let refinedResults = results.filter((summary) => {
    const topicsRef = summary.get("topicsRef");
    return topicsRef[0].id == topicId;
  });

  return refinedResults.length;
  // return await query.count();
};

export const getSummariesSecondPositionCountForTopic = async (topicId) => {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));

  const topicObject = new MetaClass();
  topicObject.id = topicId;
  query.equalTo("topicsRef", topicObject);

  query.select("topicsRef");
  query.include("topicsRef");

  let results = await query.find();

  let refinedResults = results.filter((summary) => {
    const topicsRef = summary.get("topicsRef");
    if (topicsRef.length > 1) {
      return topicsRef[1].id == topicId;
    }

    return false;
  });

  return refinedResults.length;
  // return await query.count();
};

export const getAveragePositionForTopic = async (topicId) => {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));

  const topicObject = new MetaClass();
  topicObject.id = topicId;
  query.equalTo("topicsRef", topicObject);

  query.select("topicsRef");
  query.include("topicsRef");

  let results = await query.find();

  let positionRank = results.reduce((totalRank, summary) => {
    const topicsRef = summary.get("topicsRef");
    topicsRef.forEach((topic, index) => {
      if (topic.id == topicId) {
        totalRank = totalRank + index + 1;
      }
    });

    return totalRank;
  }, 0);

  return positionRank / results.length;
};

export const trimSummariesCategoriesEntries = async () => {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));

  query.select("title", "firstCategory", "secondCategory", "otherCategories");

  let summaries = await query.find();
  alert("we got this number of summaries: " + summaries.length);
  for (let summary of summaries) {
    let fc = summary.get("firstCategory");
    let sc = summary.get("secondCategory");
    let oc = summary.get("otherCategories");

    summary.set("firstCategory", fc.trim());
    summary.set("secondCategory", sc.trim());
    summary.set("otherCategories", oc);
    summary.save();
  }
};

export const trimSummariesTopics = async () => {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query.skip(200);
  query.select(
    "title",
    "firstCategory",
    "secondCategory",
    "otherCategories",
    "topics"
  );

  let summaries = await query.find();
  alert("we got this number of summaries: " + summaries.length);
  for (let summary of summaries) {
    let topics = summary.get("topics");
    let fc = summary.get("firstCategory");
    let sc = summary.get("secondCategory");
    //let oc = summary.get('otherCategories')
    if (topics && topics.length > 0) {
      let newTopics = topics.map((item) => {
        if (item) {
          return item.trim();
        }
        return item;
      });
      summary.set("topics", newTopics);
      //summary.set('firstCategory',fc.trim())
      //summary.set('secondCategory',sc.trim())
      //summary.set('otherCategories', oc)
      summary.save();
    }
  }
};

export const getSummariesCountForAuthor = async (authorId) => {
  const query = new Parse.Query(SummaryClass);

  const author = new MetaClass();
  author.id = authorId;
  query.equalTo("authorRef", author);

  return await query.count();
};

export const getSummariesCountForCategory = async (categoryId) => {
  const firstQuery = new Parse.Query(Parse.Object.extend("SummaryNew"));

  const firstCategory = new MetaClass();
  firstCategory.id = categoryId;
  firstQuery.equalTo("firstCategoryRef", firstCategory);

  const secondCategory = new MetaClass();
  secondCategory.id = categoryId;
  const secondQuery = new Parse.Query(Parse.Object.extend("SummaryNew"));
  secondQuery.equalTo("secondCategoryRef", secondCategory);

  const mainQuery = Parse.Query.or(firstQuery, secondQuery);

  return await mainQuery.count();
};

export const getSummariesCountForFirstCategory = (categoryId) => {
  return getSummariesQueryForFirstCategory(categoryId).count();
};
export const getSummariesQueryForFirstCategory = (categoryId) => {
  const mainQuery = new Parse.Query(Parse.Object.extend("SummaryNew"));

  const categoryObject = new MetaClass();
  categoryObject.id = categoryId;
  mainQuery.equalTo("firstCategoryRef", categoryObject);

  mainQuery.select("title", "firstCategory", "secondCategory");

  return mainQuery;
};

export const getSummariesCountForSecondCategory = (categoryId) => {
  return getSummariesQueryForSecondCategory(categoryId).count();
};
export const getSummariesQueryForSecondCategory = (categoryId) => {
  const mainQuery = new Parse.Query(Parse.Object.extend("SummaryNew"));

  const categoryObject = new MetaClass();
  categoryObject.id = categoryId;
  mainQuery.equalTo("secondCategoryRef", categoryObject);

  mainQuery.select("title", "firstCategory", "secondCategory");

  return mainQuery;
};

export const getSummariesQueryForCategory = (categoryName) => {
  const firstQuery = new Parse.Query(Parse.Object.extend("SummaryNew"));
  firstQuery.equalTo("firstCategory", categoryName);

  const secondQuery = new Parse.Query(Parse.Object.extend("SummaryNew"));
  secondQuery.equalTo("secondCategory", categoryName);

  const mainQuery = Parse.Query.or(firstQuery, secondQuery);
  mainQuery.select("title", "firstCategory", "secondCategory");
  return mainQuery;
};

export const getSummariesQueryForTopic = async (topicName) => {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query.equalTo("topics", topicName);

  query.select("title");
  return await query.find();
};

// export const convertSummariesTopicsToRef = async () => {
//   let objects = await getTopics();
//   alert(JSON.stringify(objects));

//   objects.forEach(async topic => {
//     const query = new Parse.Query(Parse.Object.extend("SummaryNew"));
//     query.equalTo("topics", topic.get("title"));
//     query.select("title");

//     let summaries = await query.find();

//     for (let summary of summaries) {
//       summary.addUnique("topicsRef", topic);
//       summary.save();
//     }
//   });
// };

// export const convertSummariesCategoriesToRef = async (categoryId) => {

//   let objects = await getCategory(categoryId);

//   alert(JSON.stringify(objects));

//   objects.forEach(async category => {
//     let summariesQuery = getSummariesQueryForFirstCategory(
//       category.get("title")
//     );

//     let summaries = await summariesQuery.find();
//     alert(summaries.length)

//     for (let summary of summaries) {
//       summary.set("firstCategoryRef", category);
//       summary.save();
//     }
//   });
// };

export const adjustSummariesCategoryName = async (
  firstCategory = true,
  categoryName,
  newCategoryName
) => {
  if (firstCategory === true) {
    let summariesQuery = getSummariesQueryForFirstCategory(categoryName);
    let summaries = await summariesQuery.find();

    alert(summaries.length);
    for (let summary of summaries) {
      summary.set("firstCategory", newCategoryName);
      summary.save();
    }
  } else {
    let summariesQuery = getSummariesQueryForSecondCategory(categoryName);
    let summaries = await summariesQuery.find();
    alert(summaries.length);
    for (let summary of summaries) {
      summary.set("secondCategory", newCategoryName);
      summary.save();
    }
  }
};

export const getRandomSummary = async () => {
  const mainQuery = new Parse.Query(Parse.Object.extend("SummaryNew"));
  mainQuery.select(
    "title",
    "firstCategoryRef",
    "secondCategoryRef",
    "topicsRef"
  );
  mainQuery.limit(1);
  const randomNumber = Math.random() * 250;
  mainQuery.skip(randomNumber);
  return await mainQuery.find();
};

export const convertHighlightsIntoObjects = async () => {
  const mainQuery = new Parse.Query(Parse.Object.extend("SummaryNew"));
  mainQuery.exists("snippets");
  mainQuery.select("snippets");

  const summaries = await mainQuery.find();
  alert(summaries.length + ", and the title: " + summaries[0].get("title"));

  for (let summary of summaries) {
    const snippetsArray = summary.get("snippets");
    for (let snippet of snippetsArray) {
      const SnippetClass = Parse.Object.extend("Highlight");
      const newSnippet = new SnippetClass();
      newSnippet.set("text", snippet);
      newSnippet.set("summaryRef", summary);
      newSnippet.save();
    }
  }
};

export const updateSummaryQuizzesCount = async () => {
  const query = new Parse.Query(Parse.Object.extend("SummaryQuiz"));

  var pipelineObject = [
    { group: { objectId: "$summaryRef", count: { $sum: 1 } } },
  ];

  const quizzesCountArray = await query.aggregate(pipelineObject);
  quizzesCountArray.forEach((currentSummary) => {
    let summaryObject = new SummaryClass();
    summaryObject.id = currentSummary.objectId;
    summaryObject.set("quizzesCount", currentSummary.count);
    summaryObject.save();
  });
};

export const convertCategoriesNamesIntoCategoryRef = async () => {
  const quizCategoriesIdsMapping = {
    HISTORIC_EVENT: { id: "HFJE6CGOFq", name: "حدث تاريخي" },
    DEFINITION: {
      id: "xzWjilXwWq",
      name: "تعريفات",
    },
    CHARACTERISTICS: { id: "xzWjilXwWq", name: "خصائص و صفات" },
    GENERAL: { id: "zqEOg68mkf", name: "ثقافة عامة" },
    SCIENCE: { id: "ZyDOJHPcyr", name: "علمية" },
    RELIGIOUS: { id: "K1mSGEIxRB", name: "دينية" },
    PEOPLE: { id: "5Eao8nsJ9W", name: "شخصيات" },
  };
  const query = new Parse.Query(Parse.Object.extend("SummaryQuiz"));

  query.limit(200);
  query.skip(0);
  query.exists('categories')
  query.doesNotExist('categoryRef')

  const quizzesArray = await query.find();
  quizzesArray.forEach((quiz) => {
    let categoriesInStrings = quiz.get("categories");
    if (categoriesInStrings && categoriesInStrings.length > 0) {
      let firstCategoryInString = categoriesInStrings[0];
       let categoryId = quizCategoriesIdsMapping[firstCategoryInString].id
       let meta = new MetaClass()
       meta.id = categoryId
       quiz.set("categoryRef", meta)
       quiz.save()
    }

    
  });
};

export const updateQuizzesCategoriesCount = async () => {
  const metaQuery = new Parse.Query(Parse.Object.extend("SummaryMeta"));
  metaQuery.equalTo('type', 'QuizCategory')

  const quizCategories = await metaQuery.find()

  const query = new Parse.Query(Parse.Object.extend("SummaryQuiz"));
  //query.exists('categoryRef')

  var pipelineObject = {
    group: { objectId: "$categoryRef", count: {"$sum": 1} },
  };

  const quizCategoriesCountByCategoryId = await query
    .aggregate(pipelineObject)

  quizCategories.forEach(quizCategory => {
    let categoryCount = quizCategoriesCountByCategoryId.filter(quizCategoryWithCount => quizCategory.id == quizCategoryWithCount.objectId)[0].count

    //console.log('id for ' + quizCategory.get('title') + ' is '+ categoryCount)
    quizCategory.set('count', categoryCount)
    quizCategory.save()
  })
  
  
};
