import React from "react";
import Button from "@material-ui/core/Button";
import { Link } from "react-admin";
export const CreateRelatedQuestionButton = ({ record }) => (<Button style={{ backgroundColor: "rgba(0,0,0,0.1)", marginTop: 20 }} component={Link} to={{
  pathname: "/summaryQuiz/create",
  state: { record: { summaryRef: record.id } }
}}>
  اضف سؤال الي هذا الملخص
  </Button>);
export const ShowRelatedQuestionsButton = ({ record }) => (<Button style={{ backgroundColor: "rgba(0,0,0,0.1)", marginTop: 20 }} component={Link} to={{
  pathname: "/SummaryQuiz",
  search: `filter={"summaryRef":"${record.id}"}`
}}>
  اعرض جميع الاسئلة لهذا الملخص
  </Button>);
export const CreateRelatedHighlightButton = ({ record }) => (<Button style={{ backgroundColor: "rgba(0,0,0,0.1)", marginTop: 20 }} component={Link} to={{
  pathname: "/highlight/create",
  state: { record: { summaryRef: record.id } }
}}>
  اضف مقتطف الي هذا الملخص
  </Button>);
export const ShowRelatedHighlightButton = ({ record }) => (<Button style={{ backgroundColor: "rgba(0,0,0,0.1)", marginTop: 20 }} component={Link} to={{
  pathname: "/highlight",
  search: `filter={"summaryRef":"${record.id}"}`
}}>
  اعرض جميع مقتطفات هذا الملخص
  </Button>);
