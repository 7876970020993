import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ImageField,
  ReferenceField,
  SingleFieldList,
  ChipField,
  ReferenceInput,
  BooleanField,
  EditButton,
  CreateButton,
  ReferenceFieldController,
  SelectArrayInput,
  DateField,
  ShowButton,
  Required,
  SelectInput,
  ReferenceManyField,
  ReferenceArrayField,
  Edit,
  Create,
  Show,
  SimpleShowLayout,
  SimpleForm,
  Filter,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";

import { CardActions, ExportButton, RefreshButton } from "react-admin";
import {
  SummaryEditableFields,
  SummaryEditableFieldsForWriters,
} from "./editFields";
import { FunctionField } from "ra-ui-materialui/lib/field";
import {CreateArticleButton, CreateArticleWithHighlightsButton} from "../../dashboard/components/createArticleFromSelectedSummariesButton";

const SummariesListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    <CreateButton basePath={basePath} />

    <RefreshButton />
  </CardActions>
);

const SummaryFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
    <ReferenceInput
      source="firstCategoryRef"
      reference="SummaryMeta"
      filter={{ type: "Category" }}
      allowEmpty
    >
      <SelectInput optionText="title" />
    </ReferenceInput>

    <ReferenceInput
      source="secondCategoryRef"
      reference="SummaryMeta"
      filter={{ type: "Category" }}
      allowEmpty
    >
      <SelectInput optionText="title" />
    </ReferenceInput>
    
    <ReferenceArrayInput
        reference="SummaryMeta"
        source="topicsRef"
        perPage={100}
        filter={{ type: "Topic" }}
      >
        <AutocompleteArrayInput optionText="title" resettable />
      </ReferenceArrayInput>
      
      
    {/* <ReferenceInput
      source="topicsRef"
      reference="SummaryMeta"
      filter={{ type: "Topic" }}
      allowEmpty
    >
      <AutocompleteInput optionText="title" />
    </ReferenceInput> */}
  </Filter>
);

const SummariesBulkActionButtons = (props) => (
  <React.Fragment>
    <CreateArticleButton {...props} />
    <CreateArticleWithHighlightsButton {...props}/>
  </React.Fragment>
);

const styles = {
  image: { maxHeight: "4rem" },
};

export const SummaryList = withStyles(styles)(
  ({ classes, permissions, ...props }) => (
    <List
      {...props}
      filters={<SummaryFilter />}
      actions={<SummariesListActions />}
      bulkActionButtons={<SummariesBulkActionButtons />}
    >
      <Datagrid>
        <ImageField source="imageURL" title="title" classes={classes} />
        <TextField source="title" />
        <TextField source="subtitle" />
        <BooleanField source="isPublished" />
        <TextField source="quizzesCount" />
        {/* <TextField source="relatedSummariesRef" /> */}
        
        {/* <DateField label="تاريخ النشر" source="publishedAt" /> */}
        <DateField source="createdAt" />
        <ReferenceField source="authorRef" reference="SummaryMeta">
          <ChipField source="title" />
        </ReferenceField>

        <ReferenceField source="firstCategoryRef" reference="SummaryMeta">
          <ChipField source="title" />
        </ReferenceField>

        <ReferenceField source="secondCategoryRef" reference="SummaryMeta">
          <ChipField source="title" />
        </ReferenceField>

        <ReferenceArrayField reference="SummaryMeta" source="topicsRef">
          <SingleFieldList>
            <ChipField source="title" />
          </SingleFieldList>
        </ReferenceArrayField>

        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  )
);

export const SummaryShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
      <ReferenceField source="firstCategoryRef" reference="SummaryMeta">
            <ChipField source="title" />
          </ReferenceField>
          <ReferenceField source="secondCategoryRef" reference="SummaryMeta">
            <ChipField source="title" />
          </ReferenceField>
      <ReferenceArrayField reference="SummaryMeta" source="topicsRef">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="ملخصات مشابهة"
        reference="SummaryNew"
        source="relatedSummariesRef"
      >
        
        <Datagrid>
          <TextField source="title" />
          <TextField source="subtitle" />

          <ReferenceField source="authorRef" reference="SummaryMeta">
            <ChipField source="title" />
          </ReferenceField>

          <ReferenceField source="firstCategoryRef" reference="SummaryMeta">
            <ChipField source="title" />
          </ReferenceField>
          <ReferenceField source="secondCategoryRef" reference="SummaryMeta">
            <ChipField source="title" />
          </ReferenceField>
          <ReferenceArrayField reference="SummaryMeta" source="topicsRef">
                <SingleFieldList>
                  <ChipField source="title" />
                </SingleFieldList>
              </ReferenceArrayField>
        </Datagrid>
      </ReferenceArrayField>
      {/* <ReferenceArrayField
        label="ملخصات لها نفس الموضوعات"
        reference="SummaryMeta"
        source="topicsRef"
      >
        <Datagrid>
          <ChipField label="الموضوع" source="title" />
          <ReferenceManyField
            label="قائمة الملخصات تم تقسيها حسب الموضوع"
            reference="SummaryNew"
            target="topicsRef"
          >
            <Datagrid>
              <TextField source="title" />
              <TextField source="subtitle" />

              <ReferenceField source="authorRef" reference="SummaryMeta">
                <ChipField source="title" />
              </ReferenceField>

              <ReferenceField source="firstCategoryRef" reference="SummaryMeta">
                <ChipField source="title" />
              </ReferenceField>

              <ReferenceArrayField reference="SummaryMeta" source="topicsRef">
                <SingleFieldList>
                  <ChipField source="title" />
                </SingleFieldList>
              </ReferenceArrayField>
              <ShowButton />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Datagrid>
      </ReferenceArrayField>
      <ReferenceManyField
        label="highlights"
        reference="Highlight"
        target="summaryRef"
      >
        <Datagrid>
          <TextField source="text" />
        </Datagrid>
      </ReferenceManyField> */}
    </SimpleShowLayout>
  </Show>
);

export const SummaryEdit = ({ permissions, ...props }) => (
  <Edit {...props}>
    {permissions != "writer" && permissions != "editorAssistant"
      ? SummaryEditableFields()
      : SummaryEditableFieldsForWriters({ permissions })}
  </Edit>
);

export const SummaryCreate = (props) => (
  <Create {...props}>{SummaryEditableFields()}</Create>
);
