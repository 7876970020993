import Parse from "./app";
import {
  getSummariesCountForAuthor,
  getSummariesCountForCategory,
  getSummariesCountForTopic,
  getSummariesCountForFirstCategory,
  getSummariesCountForSecondCategory,
  getSummariesFirstPositionCountForTopic,
  getSummariesSecondPositionCountForTopic,
  getAveragePositionForTopic
} from "./summaries";

const MetaClass = Parse.Object.extend("SummaryMeta")
const SummaryClass = Parse.Object.extend("SummaryNew")

export const getTopics = async () => {
  const query = new Parse.Query(MetaClass);
  query.equalTo("type", "Topic");

  return await query.find();
};

export const getCategory = async categoryId => {

  const category = new MetaClass()
  category.id = categoryId
  
  return await category.fetch()
}

export const getAuthors = async () => {
  const query = new Parse.Query(MetaClass);
  query.equalTo("type", "Author");
  query.limit(500)
  
  return await query.find();
};


export const getCategories = async () => {
  const query = new Parse.Query(MetaClass);
  query.equalTo("type", "Category");
  
  
  return await query.find();
};

export const updateAuthorsSummariesCount = async () => {
  let objects = await getAuthors();
  alert(objects.length)

  objects.forEach(async author => {
    let count = await getSummariesCountForAuthor(
      author.id
    );

    author.set("count", count);
    author.save();
    
  });
};

export const updateCategoriesSummariesCount = async () => {
  let objects = await getCategories();
  

  objects.forEach(async category => {
    let count = await getSummariesCountForCategory(
      category.id
    );

    category.set("count", count);
    category.save();
    
  });
};

export const updateCategoriesFirstCategoryStats = async () => {
  let objects = await getCategories();
  alert(JSON.stringify(objects));

  objects.forEach(async category => {
    let count = await getSummariesCountForFirstCategory(
      category.id
    );

    category.set("firstPositionCount", count);
    category.save();
    // alert("saved " + category.get("title"));
  });
};

export const updateCategoriesSecondCategoryStats = async () => {
  let objects = await getCategories();
  alert(JSON.stringify(objects));

  objects.forEach(async category => {
    let count = await getSummariesCountForSecondCategory(
      category.id
    );

    category.set("secondPositionCount", count);
    category.save();
    // alert("saved " + category.get("title"));
  });
};


export const updateTopicsFirstPositionStats = async () => {
  let objects = await getTopics();
  

  
  objects.forEach(async topic => {
    let count = await getSummariesFirstPositionCountForTopic(topic.id);

    topic.set("firstPositionCount", count);
    topic.save();
    //alert('saved ' + topic.get('title'))
  });
};

export const updateTopicsSecondPositionStats = async () => {
  let objects = await getTopics();
  

  objects.forEach(async topic => {
    let count = await getSummariesSecondPositionCountForTopic(topic.id);

    topic.set("secondPositionCount", count);
    topic.save();
    //alert('saved ' + topic.get('title'))
  });
};

export const updateTopicsAveragePositionStats = async () => {
  let objects = await getTopics();
  

  objects.forEach(async topic => {
    let averagePosition = await getAveragePositionForTopic(topic.id);

    topic.set("averagePositionRank", averagePosition);
    topic.save();
    //alert('saved ' + topic.get('title'))
  });
};

export const updateTopicsSummariesCount = async () => {
  let objects = await getTopics();
  

  objects.forEach(async topic => {
    let count = await getSummariesCountForTopic(topic.id)

    topic.set("count", count);
    topic.save();
    
  });
}

export const updateSummaryRelatedSummariesBasedOnTopics = async () => {
  let summaryQuery = new Parse.Query("SummaryNew")

  summaryQuery.ascending('relatedSummariesRef')
  // summaryQuery.skip(388)
  summaryQuery.limit(50)
  

  const summaries = await summaryQuery.find()
  //alert(JSON.stringify(summaries.map(summary => summary.get("relatedSummariesRef"))))
  //return
  summaries.filter(summary => summary.get("relatedSummariesRef").length < 1).forEach(async summary => {
    //alert(summary.get('title'))
    let topicsRef = summary.get('topicsRef')
    let firstCategoryRef = summary.get('firstCategoryRef')
    let secondCategoryRef = summary.get('secondCategoryRef')
    let summariesWithSameTopicsQuery = new Parse.Query("SummaryNew")
    //summariesWithSameTopicsQuery.containedIn("topicsRef", topicsRef)
    if(topicsRef.length > 1) {
      //summariesWithSameTopicsQuery.equalTo('firstCategoryRef', firstCategoryRef)
      //summariesWithSameTopicsQuery.equalTo('secondCategoryRef', secondCategoryRef)
    }
    
    summariesWithSameTopicsQuery.notEqualTo('objectId', summary.id)

    const similarSummaries = await summariesWithSameTopicsQuery.find()

    const similarSummariesBestThree = similarSummaries.map(similarSummary => {
      const similarSummariesTopicsRef = similarSummary.get('topicsRef')
      const score = similarSummariesTopicsRef.reduce((scoreSoFar, currentTopicRef) => {
        return scoreSoFar + (topicsRef.filter(TR => TR.id == currentTopicRef.id).length > 0 ? 1 : 0)
      }, 0)

      return {
        similarSummary,
        score
      }
    })
    .sort((first, second) => {
      return first.score >= second.score
    })
    .slice(0,3)
    .map(obj => obj.similarSummary)

    summary.set('relatedSummariesRef', similarSummariesBestThree)
    summary.save()
  })

  //const results = await Promise.all(similarSummariesBestThree)
  //console.log(JSON.stringify(results))
  
}