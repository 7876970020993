

function getMatches(string, regex, index) {
    index || (index = 1); // default to the first capturing group
    var matches = [];
    var match;
    while ((match = regex.exec(string))) {
      matches.push(match[index]);
    }
  
    return matches;
  }

function getTitle(data) {
    var regex = /اسم الكتاب: (.*)/;
    var matches = data.match(regex);
  
    if (matches && matches.length > 0) {
      return matches[1];
    } else {
      return null;
    }
  }

  function getImageURL(data) {
    //var regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    var regex = /https:\/\/images2.imgbox.com(.*?).(png|jpg)/g;
    var results = regex.exec(data);
    if (results && results.length > 0) {
      return results[0];
    }
    // this return string, pls pay attention the second times
    return null;
  }
  
  function getIntro(data) {
    var regex = /المقدمة: (.*)/;
    var matches = data.match(regex);
  
    if (matches && matches.length > 0) {
      return matches[1];
    } else {
      return null;
    }
  }
  
  function getConculsion(data) {
    var regex = /الخاتمة: (.*)/;
    var matches = data.match(regex);
  
    if (matches && matches.length > 0) {
      return matches[1];
    } else {
      return null;
    }
  }
  
  function getSubtitle(data) {
    var regex = /اسم ثانوي: (.*)/;
    var matches = data.match(regex);
  
    if (matches && matches.length > 0) {
      return matches[1];
    } else {
      return null;
    }
  }
  
  function getBookAuthor(data) {
    var regex = /المؤلف: (.*)/;
    var matches = data.match(regex);
  
    if (matches && matches.length > 0) {
      return matches[1];
    } else {
      return null;
    }
  }
  
  function getTitles(data) {
    var regex = /(^|\n)##(\s)*([^#^\n]+)/g;
    return getMatches(data, regex, 3);
  }
  
  function getSnippets(data) {
    var regex = /(^|\n)###(\s)*([^#^\n]+)/g;
    return getMatches(data, regex, 3);
  }
  
  function getContents(data) {
    //var regex = /(^|\G|\n)##(\s)+.*\n*([^#]*)/g
  
    var regex = /[^#]## [^\n]+/g;
    var contentsArray = data.split(regex);
    contentsArray.splice(0, 1);
    var contentJoined = contentsArray.join("##");
    // contentsArray.forEach(function(entry) {
    //   // trim new lines from start and end.
    //   entry = entry.replace(/^\s+|\s+$/g, '');
    //   contentJoined = contentJoined + "##\n" + entry
    // });
  
    //var contentJoined = contentsArray.join("##")
  
    //var contentsArray = getMatches(data, regex, 3)
    //var contentJoined = contentsArray.join("\n##\n")
  
    return contentJoined;
  }
  
  function getCategories(data) {
    var regex = /التصنيفات: (.*)/;
    var matches = data.match(regex);
  
    if (matches && matches.length > 0) {
      let categories = matches[1];
      let categoriesArray = categories.split("،");
      return categoriesArray.map(category => category.trim());
    } else {
      return null;
    }
  }
  
  function getTopics(data) {
    var regex = /الموضوعات: (.*)/;
    var matches = data.match(regex);
  
    if (matches && matches.length > 0) {
      let categories = matches[1];
      let categoriesArray = categories.split("،");
      return categoriesArray.map(category => category.trim());
    } else {
      return null;
    }
  }
  
  function getReadingLevel(data) {
    var regex = /مستوى القراءة: (.*)|مستوي القراءة: (.*)/;
    var matches = data.match(regex);
  
    if (matches && matches.length > 0) {
      return matches[1];
    } else {
      return null;
    }
  }
  
  function getReadtimeInMinutes(data) {
    // 100 words per minutes
    return Math.floor(data.split(" ").length / 200);
  }

  
  export const getSummaryMeta = (originalFile) => {
    const title = getTitle(originalFile)
    const subtitle = getSubtitle(originalFile)
    const conclusion = getConculsion(originalFile)
    const intro = getIntro(originalFile)
    const readingLevel = getReadingLevel(originalFile)
    const readingTime = getReadtimeInMinutes(originalFile)
    const imageURL = getImageURL(originalFile)

    
    return {
        title,
        subtitle,
        conclusion,
        intro,
        readingLevel,
        readingTime,
        imageURL
    }
  }