import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from "react-admin";
import Parse from "./app";


const membersByUsername = {
  "molased@gmail.com": {
    role: "admin"
  },
  habeeb: {
    role: "editor"
  },
  nadine: {
    role: "guest"
  },
  mervat: {
    role: "editor"
  },
  hend: {
    role: "editor"
  },
  amira: {
    role: "writer"
  },
  aboelata: {
    role: "editor"
  },
  nermeen: {
    role: "writer"
  },
  wakeel: {
    role: "writer"
  },
  editor: {
    role: "guest"
  }
};

export default async (type, params) => {
  // console.log("parseAuth",type, params);
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    try {
      if (membersByUsername[username] == null) {
        throw Error("not allowed to enter");
      }
      const user = await Parse.User.logIn(username, password);
      localStorage.setItem("role", membersByUsername[username].role);
      return user;
    } catch (error) {
      throw Error("Wrong username / password");
    }
  }
  if (type === AUTH_LOGOUT) {
    try {
      await Parse.User.logOut();
      return Promise.resolve();
    } catch (error) {
      throw Error(error.toString());
    }
  }
  if (type === AUTH_ERROR) {
    // ...
    // Parse.User.logOut().then
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    // return Promise.resolve();
    // const { resource } = params;
    return Parse.User.current() ? Promise.resolve() : Promise.reject();
    // if (resource === 'posts') {
    //     // check credentials for the posts resource
    // }
    // if (resource === 'comments') {
    //     // check credentials for the comments resource
    // }
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.reject("Unknown method");
};
