import React, { Fragment, useState } from "react";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { List, ListItem, ListItemText, Collapse } from "@material-ui/core";
import {
  updateSummaryQuizzesCount,
  convertCategoriesNamesIntoCategoryRef,
  updateQuizzesCategoriesCount,
} from "../../parse/summaries";
import {
  updateCategoriesFirstCategoryStats,
  updateCategoriesSecondCategoryStats,
  updateCategoriesSummariesCount,
  updateTopicsFirstPositionStats,
  updateTopicsSecondPositionStats,
  updateTopicsAveragePositionStats,
  updateTopicsSummariesCount,
  updateAuthorsSummariesCount,
  updateSummaryRelatedSummariesBasedOnTopics,
} from "../../parse/meta";
import { showDone } from "../dashboard";

const ActionsListView = ({ actionsList, ...props }) => {
  return (
    <List component="div" disablePadding>
      {actionsList.map((actionItem) => {
        return (
          <ListItem
            button
            divider
            onClick={actionItem.action}
            style={{ paddingLeft: 40 }}
          >
            {actionItem.title}
          </ListItem>
        );
      })}
    </List>
  );
};
export const QuizzesRelatedActionsSection = () => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <ListItem button divider onClick={() => setOpen(!open)}>
        <ListItemText primary="مهام متعلقة بالاختبارات" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ActionsListView
          actionsList={[
            {
              action: () => {
                updateSummaryQuizzesCount().then(showDone);
              },
              title: "Update summaries quizzes count",
            },
            {
              action: () => {
                convertCategoriesNamesIntoCategoryRef().then(showDone);
              },
              title: "Convert categories names into category ref",
            },
            {
              action: () => {
                updateQuizzesCategoriesCount().then(showDone);
              },
              title: "Update quizzes categories count",
            },
          ]}
        />
      </Collapse>
    </Fragment>
  );
};
export const CategoriesRelatedActionsSection = () => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <ListItem button divider onClick={() => setOpen(!open)}>
        <ListItemText primary="مهام متعلقة بالتصنيفات" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ActionsListView
          actionsList={[
            {
              action: () => {
                updateCategoriesFirstCategoryStats().then(showDone);
              },
              title: "Update first category statistics",
            },
            {
              action: () => {
                updateCategoriesSecondCategoryStats().then(showDone);
              },
              title: "Update second category statistics",
            },
            {
              action: () => {
                updateCategoriesSummariesCount().then(showDone);
              },
              title: "Update category summaries count",
            },
          ]}
        />
      </Collapse>
    </Fragment>
  );
};
export const TopicsRelatedActionsSection = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <ListItem button divider onClick={() => setOpen(!open)}>
        <ListItemText primary="مهام متعلقة بالموضوعات" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ActionsListView
          actionsList={[
            {
              action: () => {
                updateTopicsFirstPositionStats().then(showDone);
              },
              title: "Update topics first position statistics",
            },
            {
              action: () => {
                updateTopicsSecondPositionStats().then(showDone);
              },
              title: "Update topics second position statistics",
            },
            {
              action: () => {
                updateTopicsAveragePositionStats().then(showDone);
              },
              title: "Update topics average rank statistics",
            },
            {
              action: () => {
                updateTopicsSummariesCount().then(showDone);
              },
              title: "Update topics summaries count",
            },
            {
              action: () => {
                updateSummaryRelatedSummariesBasedOnTopics().then(showDone);
              },
              title: "Update summary related summaries based on topics",
            },
          ]}
        />
      </Collapse>
    </Fragment>
  );
};

export const AuthorsRelatedActionsSection = () => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <ListItem button divider onClick={() => setOpen(!open)}>
        <ListItemText primary="مهام متعلقة بالمؤلفين" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <ActionsListView
          actionsList={[
            {
              action: () => {
                updateAuthorsSummariesCount().then(showDone);
              },
              title: "Update authors summaries count",
            },
          ]}
        />
      </Collapse>
    </Fragment>
  );
};
