import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from "react-admin";

import Parse from "./app";

const mapParseResults = async (o) => {
  let finalObject = { id: o.id, ...o.attributes };

  if (o.attributes.relatedSummariesRef) {
    let relatedSummariesRef = o.attributes.relatedSummariesRef.map((tr) => {
      return tr.id;
    });

    finalObject = { ...finalObject, relatedSummariesRef };
  }

  if (o.attributes.topicsRef) {
    let topicsRef = o.attributes.topicsRef.map((tr) => {
      return tr.id;
    });

    finalObject = { ...finalObject, topicsRef: topicsRef };
  }

  if (o.attributes.firstCategoryRef) {
    let firstCategoryRef = o.attributes.firstCategoryRef.id;
    finalObject = { ...finalObject, firstCategoryRef: firstCategoryRef };
  }

  if (o.attributes.secondCategoryRef) {
    let secondCategoryRef = o.attributes.secondCategoryRef.id;
    finalObject = { ...finalObject, secondCategoryRef };
  }

  if (o.attributes.authorRef) {
    let authorRef = o.attributes.authorRef.id;
    finalObject = { ...finalObject, authorRef };
  }

  if (o.attributes.summaryRef) {
    let summaryRef = o.attributes.summaryRef.id;
    finalObject = { ...finalObject, summaryRef };
  }

  if (o.attributes.categoryRef) {
    let categoryRef = o.attributes.categoryRef.id;
    finalObject = { ...finalObject, categoryRef };
  }

  if (o.attributes.originalFile) {
    let contentFile = o.attributes.originalFile;
    const fetchResult = await fetch(contentFile.url());
    const originalFile = await fetchResult.text();

    finalObject = { ...finalObject, originalFile };
  }

  return finalObject;
};

const textToParseFile = (text) => {
  return new Promise((resolve, reject) => {
    var textFileAsBlob = new Blob([text], { type: "text/plain" });

    var reader = new FileReader();
    reader.readAsDataURL(textFileAsBlob);

    reader.onloadend = function () {
      const base64data = reader.result;
      var file = new Parse.File("summary-content.txt", { base64: base64data });

      file.save().then((savedFile) => {
        if (!savedFile) {
          reject("error in saving the file");
        }
        resolve(savedFile);
      });
    };
  });
};

const idToParseObject = (id, objectType) => {
  let ParseObject = Parse.Object.extend(objectType);
  const newObject = new ParseObject();
  newObject.id = id;
  return newObject;
};

const idsToParseObject = (objectType) => {
  return (id) => {
    let ParseObject = Parse.Object.extend(objectType);
    const newObject = new ParseObject();
    newObject.id = id;
    return newObject;
  };
};

const mapDataToParse = async (data) => {
  const keys = Object.keys(data).filter((o) =>
    o == "id" || o == "createdAt" || o == "updatedAt" ? false : true
  );

  const newData = keys.reduce((r, f, i) => {
    r[f] = data[f];
    return r;
  }, {});

  if (newData.categoryRef) {
    const id = newData.categoryRef;
    const object = idToParseObject(id, "SummaryMeta");
    newData.categoryRef = object;
  }

  if (newData.topicsRef) {
    const ids = newData.topicsRef;
    const objects = ids.map(idsToParseObject("SummaryMeta"));
    newData.topicsRef = objects;
  }

  if (newData.relatedSummariesRef) {
    const ids = newData.relatedSummariesRef;
    const objects = ids.map(idsToParseObject("SummaryNew"));
    newData.relatedSummariesRef = objects;
  }

  if (newData.firstCategoryRef) {
    const id = newData.firstCategoryRef;
    const object = idToParseObject(id, "SummaryMeta");
    newData.firstCategoryRef = object;
  }

  if (newData.secondCategoryRef) {
    const id = newData.secondCategoryRef;
    const object = idToParseObject(id, "SummaryMeta");
    newData.secondCategoryRef = object;
  }

  if (newData.authorRef) {
    const id = newData.authorRef;
    const object = idToParseObject(id, "SummaryMeta");
    newData.authorRef = object;
  }

  if (newData.summaryRef) {
    const id = newData.summaryRef;
    const object = idToParseObject(id, "SummaryNew");
    newData.summaryRef = object;
  }

  if (newData.originalFile) {
    const text = newData.originalFile;
    const textFile = await textToParseFile(text);
    newData.originalFile = textFile;
  }

  return newData;
};

export default async (type, resource, params) => {
  const resourceObj = Parse.Object.extend(resource);
  const query = new Parse.Query(resourceObj);

  //alert(JSON.stringify(params) + ', ' + type)
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const { filter } = params;

      console.log(JSON.stringify(params));
      Object.keys(filter).forEach((f) => {
        if (f == "q") {
          query.contains("title", filter[f]);
        } else if (f == "isGeneralQuestion") {
          query.equalTo(f, filter[f]);
        } else if (f == "firstCategoryRef" || f == "secondCategoryRef") {
          query.equalTo(f, idToParseObject(filter[f], "SummaryMeta"));
        } else if (f == "topicsRef") {
          query.containsAll(
            f,
            filter[f].map((topicId) => idToParseObject(topicId, "SummaryMeta"))
          );
        } else {
          return query.contains(f, filter[f]);
        }
      });

      const count = await query.count();
      query.limit(perPage);
      query.skip((page - 1) * perPage);

      if (order === "DESC") query.descending(field);
      else if (order === "ASC") query.ascending(field);

      const results = await query.find();

      const promises = results.map(mapParseResults);
      const data = await Promise.all(promises);

      return {
        total: count,
        data: data,
      };
    }
    case GET_ONE: {
      const result = await query.get(params.id);
      return {
        data: await mapParseResults(result),
      };
    }
    case GET_MANY: {
      const objectIdsQuery = params.ids.map((id) =>
        new Parse.Query(resourceObj).get(id)
      );

      const results = await Promise.all(objectIdsQuery);

      const promises = results.map(mapParseResults);
      const data = await Promise.all(promises);

      return {
        total: results.length,
        data: data,
      };
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;

      query.equalTo(params.target, idToParseObject(params.id, "SummaryMeta"));
      const count = await query.count();
      query.limit(perPage);
      query.skip((page - 1) * perPage);
      if (order === "DESC") query.descending(field);
      else if (order === "ASEC") query.ascending(field);

      const results = await query.find();

      const promises = results.map(mapParseResults);
      const data = await Promise.all(promises);

      return {
        total: count,
        data: data,
      };
    }
    case CREATE: {
      const resObj = new resourceObj();
      // Object.keys(params.data).map(key=>resObj.set(key, params.data[key]));
      try {
        const data = await mapDataToParse(params.data);
        const r = await resObj.save(data);
        const dataToShow = await mapParseResults(r);
        return { data: { id: r.id, ...dataToShow } };
      } catch (error) {
        return error;
      }
    }
    case UPDATE: {
      try {
        const obj = await query.get(params.id);
        const data = await mapDataToParse(params.data);
        const r = await obj.save(data);

        const dataToShow = await mapParseResults(r);
        return { data: { id: r.id, ...dataToShow } };
      } catch (error) {
        throw Error(error.toString());
      }
    }
    case UPDATE_MANY: {
      try {
        const qs = await Promise.all(
          params.ids.map((id) => new Parse.Query(resourceObj).get(id))
        );
        const data = await mapDataToParse(params.data);
        qs.map((q) => q.save(data));
        return { data: params.ids };
      } catch {
        throw Error("Failed to update all");
      }
    }
    case DELETE: {
      try {
        const obj = await query.get(params.id);
        const data = { data: { id: obj.id, ...obj.attributes } };
        await obj.destroy();
        return data;
      } catch (error) {
        throw Error("Unable to delete");
      }
    }
    case DELETE_MANY: {
      try {
        const qs = await Promise.all(
          params.ids.map((id) => new Parse.Query(resourceObj).get(id))
        );
        await Promise.all(qs.map((obj) => obj.destroy()));
        return { data: params.ids };
      } catch (error) {
        throw Error("Unable to delete all");
      }
    }
    default:
      break;
  }
};
