import React from "react";
import {
  TextInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  FormDataConsumer,
  SimpleForm,
  Toolbar,
  SaveButton,
  BooleanInput
} from "react-admin";
import { QuestionType, questionTypeChoices, QuestionCategory, questionCategoryChoices } from "./quiz";



const validateQuizFields = values => {
  const errorMessage = "هذه الخانة إلزامية";
  const filteredKeys = ["question", "type", "summaryRef"].filter(
    key => values[key] == null || values[key].length == 0
  );
  const errors = filteredKeys.reduce((errors, currentKey) => {
    errors[currentKey] = [errorMessage];
    return errors;
  }, {});
  if (values["type"]) {
    switch (values["type"]) {
      case QuestionType.MULTICHOICE.id:
        if (values["possibleAnswers"] == null) {
          errors["possibleAnswers"] = [errorMessage];
        }
        if (values["correctAnswerIndex"] == null) {
          errors["correctAnswerIndex"] = [errorMessage];
        }
        break;
      case QuestionType.MULTICHOICE_MULTIANSWERS.id:
        if (values["possibleAnswers"] == null) {
          errors["possibleAnswers"] = [errorMessage];
        }
        if (values["correctAnswersIndices"] == null) {
          errors["correctAnswersIndices"] = [errorMessage];
        }
        break;
      case QuestionType.TRUE_OR_FALSE.id:
        if (values["isCorrectStatement"] == null) {
          errors["isCorrectStatement"] = [errorMessage];
        }
        break;
      default:
    }
  }
  return errors;
};


const QuizCreateToolbar = props => (
  <Toolbar {...props} >
      <SaveButton
          label="حفظ"
          redirect="show"
          submitOnEnter={true}
      />
      <SaveButton
          label="حفظ ثم اضافة سؤال جديد"
          redirect={false}
          submitOnEnter={false}
          variant="flat"
      />
  </Toolbar>
);

export const QuizEditableFields = () => (
  <SimpleForm validate={validateQuizFields} redirect="show" toolbar={<QuizCreateToolbar />}>
    <BooleanInput source="isGeneralQuestion" />
    <TextInput multiline source="paragraph" />
    <TextInput source="pageNumber" />
    <TextInput source="question" />
    <SelectInput
      defaultValue={QuestionType.TRUE_OR_FALSE.id}
      source="type"
      choices={questionTypeChoices}
    />
    <ReferenceInput
        source="categoryRef"
        reference="SummaryMeta"
        filter={{ type: "QuizCategory" }}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
    {/* <SelectArrayInput 
      source="categories"
      choices={questionCategoryChoices}
    /> */}
    
    <FormDataConsumer>
      {({ formData, ...rest }) => {
        const questionType = formData.type;
        switch (questionType) {
          case QuestionType.MULTICHOICE.id:
          case QuestionType.MULTICHOICE_MULTIANSWERS.id:
            return (
              <TextInput multiline
                //defaultValue={[]}
                label="الاختيارات - برجاء وضع فاصلة بين كل اختيار"
                source="possibleAnswers"
                format={answers => {
                  return answers ? answers.join("،") : "";
                }}
                parse={answers => answers.split("،")}
              />
            );
          default:
            return null;
        }
      }}
    </FormDataConsumer>

    <FormDataConsumer>
      {({ formData, ...rest }) => {
        const questionType = formData.type;
        let possibleAnswersArray = [];
        if (questionType != QuestionType.TRUE_OR_FALSE.id) {
          const possibleAnswers = formData.possibleAnswers
            ? formData.possibleAnswers.toString()
            : "";
          possibleAnswersArray = possibleAnswers
            .split(",")
            .map((possibleAnswer, index) => ({
              id: index,
              name: possibleAnswer
            }));
        }
        switch (questionType) {
          case QuestionType.MULTICHOICE.id:
            return (
              <SelectInput
                label="الاجابة الصحيحة"
                source="correctAnswerIndex"
                choices={possibleAnswersArray}
              />
            );
          case QuestionType.MULTICHOICE_MULTIANSWERS.id:
            return (
              <SelectArrayInput
                label="الاجابات الصحيحة"
                source="correctAnswersIndices"
                choices={possibleAnswersArray}
              />
            );
          default:
            return (
              <SelectInput
                label="هل العبارة صحيحة ؟"
                source="isCorrectStatement"
                choices={[
                  { id: true, name: "عبارة صحيحة" },
                  { id: false, name: "عبارة خاطئة" }
                ]}
              />
            );
        }
      }}
    </FormDataConsumer>

    <ReferenceInput source="summaryRef" reference="SummaryNew">
      <SelectInput optionText="title" />
    </ReferenceInput>
  </SimpleForm>
);