import React from "react";
import { BooleanField } from "react-admin";
import { QuestionType } from "./quiz";


const QuizPossibleAnswersField = ({ record }) => (
  <ul>
    {record.possibleAnswers.map(item => (
      <li style={{ marginTop: 5 }} key={item}>
        {item}
      </li>
    ))}
  </ul>
);
const QuizCorrectAnswersField = ({ record }) => {
  let correctAnswersIndices = record.correctAnswersIndices;
  const doesContainsIndex = index => {
    return (
      correctAnswersIndices.filter(
        correctAnswerIndex => correctAnswerIndex == index
      ).length > 0
    );
  };
  return (
    <ul>
      {record.possibleAnswers.map((item, index) =>
        doesContainsIndex(index) ? (
          <li style={{ marginTop: 5 }} key={item}>
            {item}
          </li>
        ) : null
      )}
    </ul>
  );
};
const QuizCorrectAnswerField = ({ record }) => {
  let correctAnswerIndex = record.correctAnswerIndex;
  return (
    <ul>
      {record.possibleAnswers.map((item, index) =>
        index == correctAnswerIndex ? (
          <li style={{ marginTop: 5 }} key={item}>
            {item}
          </li>
        ) : null
      )}
    </ul>
  );
};
export const ConditionalPossibleAnswersField = ({ record, ...rest }) =>
  record.type != QuestionType.TRUE_OR_FALSE.id && (
    <QuizPossibleAnswersField record={record} {...rest} />
  );
export const ConditionalCorrectAnswerField = ({ record, ...rest }) =>
  record.type === QuestionType.MULTICHOICE.id && (
    <QuizCorrectAnswerField record={record} {...rest} />
  );
export const ConditionalCorrectAnswersField = ({ record, ...rest }) =>
  record.type === QuestionType.MULTICHOICE_MULTIANSWERS.id && (
    <QuizCorrectAnswersField record={record} {...rest} />
  );
export const ConditionalIsCorrectStatementField = ({ record, ...rest }) =>
  record.type === QuestionType.TRUE_OR_FALSE.id && (
    <BooleanField {...rest} record={record} source="isCorrectStatement" />
  );
