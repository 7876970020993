import React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  EditButton,
  DateField,
  AutocompleteInput,
  Edit,
  Create,
  SimpleForm,
  Filter
} from "react-admin";

const redirect = (basePath, id, data) => `/SummaryNew/${data.summaryRef}/1`;

const HighlightFilter = props => (
  <Filter {...props}>
    <ReferenceInput source="summaryRef" reference="SummaryNew">
      <AutocompleteInput label="حسب الملخص" optionText="title" alwaysOn />
    </ReferenceInput>
  </Filter>
);

export const HighlightList = props => (
  <List {...props} filters={<HighlightFilter />}>
    <Datagrid>
      <TextField source="text" />
      <DateField source="createdAt" />
      <ReferenceField source="summaryRef" reference="SummaryNew">
        <TextField source="title" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
);

const validateHighlightFields = values => {
  const filteredKeys = ["title", "summaryRef", "text"].filter(
    key => values[key] == null || values[key].length == 0
  );
  const errors = filteredKeys.reduce((errors, currentKey) => {
    errors[currentKey] = ["هذه الخانة إلزامية"];
    return errors;
  }, {});

  return errors;
};

export const HighlightEdit = props => (
  <Edit {...props}>
    {getEditAndCreateFields()}
  </Edit>
);

export const HighlightCreate = props => (
  <Create {...props}>{getEditAndCreateFields()}</Create>
);

function getEditAndCreateFields() {
  return (
    <SimpleForm validate={validateHighlightFields} redirect={redirect}>
      <TextInput multiline source="text" />
      <ReferenceInput source="summaryRef" reference="SummaryNew" perPage={100}>
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
    </SimpleForm>
  );
}
