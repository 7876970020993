import React, {useState} from "react";
import {
    Button,
} from 'react-admin';



export const CreateArticleButton = ({ selectedIds }) => {
    
    return (
        <Button
            label="ra.action.create_article"
            href={`/#/summariesIdsArticle?summariesIds=${selectedIds}`}
            target="_blank"
        />
            
        
    );
};

export const CreateArticleWithHighlightsButton = ({ selectedIds }) => {
    
    return (
        <Button
            label="ra.action.create_article_highlights"
            href={`/#/summariesIdsArticle?summariesIds=${selectedIds}&showHighlights=true`}
            target="_blank"
        />
            
        
    );
};

