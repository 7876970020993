export default  {
    ra: {
        action: {
            delete: 'حذف',
            show: 'إِظهار',
            list: 'القائمة',
            save: 'حِفظ',
            create: 'إِنشاء',
            edit: 'تعديل',
            cancel: 'إِلغاء',
            refresh: 'تحديث',
            add_filter: 'إِضافة فلتر',
            remove_filter: 'حذف فلتر',
            back: 'رجوع',
            bulk_actions: 'اكثر من ملخص',
            create_article: 'انشاء مقالة',
            create_article_highlights: 'انشاء مقالة مع مقتطفات'
        },
        boolean: {
            true: 'نعم',
            false: 'لا',
        },
        page: {
            list: '%{name}قائمة',
            edit: '%{name} #%{id} تعديل',
            show: '%{name} #%{id} إِظهار',
            create: '%{name} إِنشاء',
            delete: '%{name} #%{id} حذف',
            dashboard: 'الصفحة الرئيسية',
            not_found: 'الصفحة غير موجودة'
        },
        input: {
            image: {
                upload_several: 'أودع الملفات المراد تحميلها، أو إضغط للإختيار الملفات',
                upload_single: 'أودع الملف المراد تحميله، أو إضغط للإختيار الملف',
            },
        },
        message: {
            yes: 'نعم',
            no: 'لا',
            are_you_sure: 'هل أنت متأكد ؟',
            about: 'في ما يخص',
            not_found: 'هذا الرابط غير صالح، أو إنك لم تتبع الرابط المناسب',
        },
        navigation: {
            no_results: 'لا توجد نتيجة',
            page_out_of_boundaries: 'الصفحة %{page} خارج الحدود',
            page_out_from_end: 'نهاية التصفّح',
            page_out_from_begin: 'الصفحة يجب أن تكون أكبر من 1',
            page_range_info: '%{total} من %{offsetEnd}-%{offsetBegin}',
            next: 'التالي',
            prev: 'السابق',
        },
        auth: {
            username: 'المعرّف',
            password: 'كلمة السر',
            sign_in: 'دخول',
            sign_in_error: 'لم يتم الربط.. الرجاء إعادة العملية',
            logout: 'خروج',
        },
        notification: {
            updated: 'تم التحديث',
            created: 'تم الإنشاء',
            deleted: 'تم الحذف',
            item_doesnt_exist: 'غير موجود',
            http_error: 'خطأ في الربط مع  السيرفر',
        },
        validation: {
            required: 'هذه الخانة إلزامية',
            minLength: 'رموز على الأقل %{min}',
            maxLength: 'رموز على الأكثر %{max}',
            minValue: '%{min} على الأقل',
            maxValue: '%{max} رموز على الأكثر',
            number: 'يجب أن يكون رقمًا',
            email: 'يجب أن يكون بريدًا إلكترونيّا',
        },
        
    },
    resources: {
        SummaryNew: {
            name: 'الملخصات',
            fields: {
                title: 'اسم الكتاب',
                subtitle: 'الاسم الفرعي',
                firstCategoryRef: 'تصنيف اول',
                secondCategoryRef: 'تصنيف ثاني',
                topicsRef: 'الموضوعات',
                authorRef: 'المؤلف',
                createdAt: 'تاريخ الرفع',
                publishedAt: 'تاريخ النشر',
                isPublished: 'تم النشر',
                summaryDescription: 'المقدمة',
                originalFile: 'المحتوي',
                conclusion: 'الخاتمة',
                imageURL: 'رابط غلاف الكتاب',
                readingLevelRef: 'مستوي القراءة',
                summaryWriter: 'كاتب الملخص',
                originalFile: 'محتوي الملخص',
                quizzesCount: 'عدد الاسئلة'
            },
            actions: {
                createArticle: 'انشاء مقال'
            }
        },
        SummaryMeta: {
            name: 'تصنيف، موضوع، مؤلف',
            fields: {
                title: 'الاسم',
                count: 'العدد الكلي',
                type: 'النوع',
                firstPositionCount: 'عدد مرات التواجد في الخانة الاولي',
                secondPositionCount: 'عدد مرات التواجد في الخانة الثانية',
                averagePositionRank: 'متوسط الترتيب في الخانات' 
            }
        },
        Highlight: {
            name: 'المقتطفات',
            fields: {
                title: 'الاسم',
                summaryRef: 'الملخص',
                createdAt: 'تاريخ الرفع',
                text: 'المحتوي'
            }
        },
        SummaryQuiz: {
            name: 'اسئلة',
            fields: {
                question: 'السؤال',
                type: 'نوع السؤال',
                possibleAnswers: 'الاختيارات',
                correctAnswersIndices: 'الاجابات الصحيحة',
                correctAnswerIndex: 'الاجابة الصحيحة',
                isCorrectStatement: 'هل العبارة صحيحة',
                updatedAt: 'تاريخ التحديث',
                summaryRef: 'الملخص',
                paragraph: 'الفقرة',
                pageNumber: 'رقم الصفحة',
                isGeneralQuestion: 'سؤال عام',
                categories: 'تصنيفات',
                categoryRef: 'تصنيف السؤال'
            }

        }
    },
    myRoot: {
        hello: 'then hello'
    }
};