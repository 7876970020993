import React from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Chip from "@material-ui/core/Chip";
import {
  List,
  Datagrid,
  TextField,
  BooleanInput,
  NumberField,
  DisabledInput,
  ArrayField,
  Labeled,
  translate,
  BooleanField,
  ReferenceField,
  SingleFieldList,
  ChipField,
  RadioButtonGroupInput,
  ReferenceInput,
  FunctionField,
  EditButton,
  CreateButton,
  DateField,
  ReferenceManyField,
  ReferenceArrayInput,
  ReferenceArrayField,
  AutocompleteInput,
  Edit,
  Create,
  Filter,
  CardActions,
  SelectInput,
  TextInput,
  SimpleForm,
  SaveButton,
  Toolbar,
} from "react-admin";

import { Drawer } from "@material-ui/core";
import { Route } from "react-router";

import MaterialUITextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import {
  ConditionalPossibleAnswersField,
  ConditionalCorrectAnswerField,
  ConditionalCorrectAnswersField,
  ConditionalIsCorrectStatementField,
} from "./customFields";
import { QuizEditableFields } from "./editFields";

export const QuestionType = {
  MULTICHOICE: { id: "MULTICHOICE", name: "متعدد الاختيار" },
  MULTICHOICE_MULTIANSWERS: {
    id: "MULTICHOICE_MULTIANSWERS",
    name: "متعدد الاختيار - امكانية اختيار اكثر من اجابة",
  },
  TRUE_OR_FALSE: { id: "TRUE_OR_FALSE", name: "عبارة صحيحة ام خاطئة" },
};

export const QuestionCategory = {
  HISTORIC_EVENT: { id: "HISTORIC_EVENT", name: "حدث تاريخي" },
  DEFINITION: {
    id: "DEFINITION",
    name: "تعريفات",
  },
  CHARACTERISTICS: { id: "CHARACTERISTICS", name: "خصائص و صفات" },
  GENERAL: { id: "GENERAL", name: "ثقافة عامة" },
  SCIENCE: { id: "SCIENCE", name: "علمية" },
  RELIGIOUS: { id: "RELIGIOUS", name: "دينية" },
  PEOPLE: { id: "PEOPLE", name: "شخصيات" },
};

export const questionTypeChoices = [
  QuestionType.MULTICHOICE,
  QuestionType.MULTICHOICE_MULTIANSWERS,
  QuestionType.TRUE_OR_FALSE,
];

export const questionCategoryChoices = [
  QuestionCategory.DEFINITION,
  QuestionCategory.GENERAL,
  QuestionCategory.CHARACTERISTICS,
  QuestionCategory.HISTORIC_EVENT,
  QuestionCategory.SCIENCE,
  QuestionCategory.RELIGIOUS,
  QuestionCategory.PEOPLE,
];

const QuizFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="summaryRef" reference="SummaryNew">
      <AutocompleteInput label="حسب الملخص" optionText="title" alwaysOn />
    </ReferenceInput>
    <BooleanInput source="isGeneralQuestion" />
  </Filter>
);

const QuizListActions = ({ basePath }) => (
  <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
);

export const QuizCreate = (props) => (
  <Create {...props}>{QuizEditableFields()}</Create>
);

export const QuizEdit = (props) => (
  <Edit {...props}>{QuizEditableFields()}</Edit>
);

const CustomQuickQuizEditToolbar = props => (
  
    <Button style={{width: '80%', backgroundColor: 'black', color: 'white', margin: 10}} onClick={props.handleSubmit} >Save</Button>
  
      
  
);

export const MiniQuizEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<CustomQuickQuizEditToolbar />}>
    
    <TextInput source="question" />
    
    <ReferenceInput
        source="categoryRef"
        reference="SummaryMeta"
        filter={{ type: "QuizCategory" }}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>
      
  </SimpleForm>
  </Edit>
);

const QuizList = (props) => {
  const handleClose = () => {
    props.push("/SummaryQuiz");
  };

  return (
    <React.Fragment>
      <List {...props} filters={<QuizFilter />} actions={<QuizListActions />}>
        <Datagrid >
          <TextField source="question" />
          {/* <TextField source="paragraph"/>
        <TextField source="pageNumber" />
        <BooleanField source="isGeneralQuestion" /> */}
        <ReferenceField source="categoryRef" reference="SummaryMeta">
            <ChipField source="title" />
          </ReferenceField>
          
          

          <ConditionalPossibleAnswersField source="possibleAnswers" />
          <ConditionalCorrectAnswerField source="correctAnswerIndex" />
          <ConditionalCorrectAnswersField source="correctAnswersIndices" />
          <ConditionalIsCorrectStatementField source="isCorrectStatement" />
          <FunctionField
            source="type"
            render={(record) => <Chip label={QuestionType[record.type].name} />}
          />
          <ReferenceField source="summaryRef" reference="SummaryNew">
            <TextField source="title" />
          </ReferenceField>
          {/* <DateField source="updatedAt" /> */}
          <EditButton />
        </Datagrid>
      </List>
      <Route
        path="/SummaryQuiz/create"
        render={({ match }) => (
          <Drawer open={!!match} anchor="right" onClose={handleClose}>
            <QuizCreate {...props} />
          </Drawer>
        )}
      />
      <Route path="/SummaryQuiz/:id">
        {({ match }) => {
          const isMatch = match && match.params && match.params.id !== "create";

          return (
            <Drawer open={isMatch} anchor="right" onClose={handleClose}>
              {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
              {isMatch ? (
                <MiniQuizEdit
                  id={match.params.id}
                  {...props}
                />
              ) : null}
            </Drawer>
          );
        }}
      </Route>
    </React.Fragment>
  );
};

export default connect(undefined, { push })(QuizList);
