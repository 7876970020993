import React from "react";
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  FormDataConsumer,
  CloneButton,
  NumberField,
  DisabledInput,
  ArrayField,
  ReferenceField,
  SingleFieldList,
  ImageField,
  ChipField,
  ReferenceInput,
  BooleanField,
  FunctionField,
  EditButton,
  ShowButton,
  CreateButton,
  SelectArrayInput,
  DateField,
  SelectInput,
  ReferenceManyField,
  ReferenceArrayInput,
  ReferenceArrayField,
  AutocompleteInput,
  Edit,
  Create,
  SimpleForm,
  Filter
} from "react-admin";
import { Show, SimpleShowLayout } from "ra-ui-materialui/lib/detail";

const MetaFilter = props => (
  <Filter {...props}>
    <TextInput label="بحث" source="title" />
    <SelectInput
      label="اظهر"
      source="type"
      choices={[
        { id: "Topic", name: "موضوعات" },
        { id: "Category", name: "تصنيفات" },
        { id: "Author", name: "مؤلف" },
        { id: "QuizCategory", name: "تصنيفات الاسئلة" }
      ]}
      alwaysOn
    />
  </Filter>
);

export const MetaList = props => (
  <List
    {...props}
    filters={<MetaFilter />}
    filterDefaultValues={{ type: "Topic" }}
  >
    <Datagrid>
      <TextField source="title" />
      <NumberField source="firstPositionCount" />
      <NumberField source="secondPositionCount" />
      <NumberField source="averagePositionRank" />
      <NumberField source="count" />

      <DateField source="updatedAt" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const validateMetaFields = values => {
  const filteredKeys = ["title", "type"].filter(
    key => values[key] == null || values[key].length == 0
  );
  const errors = filteredKeys.reduce((errors, currentKey) => {
    errors[currentKey] = ["هذه الخانة إلزامية"];
    return errors;
  }, {});

  return errors;
};

export const MetaShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <ChipField source="title" />
      <ReferenceManyField
        label="قائمة الملخصات"
        reference="SummaryNew"
        target="topicsRef"
      >
        <Datagrid>
          <TextField source="title" />
          <TextField source="subtitle" />

          <ReferenceField source="authorRef" reference="SummaryMeta">
            <ChipField source="title" />
          </ReferenceField>

          <ReferenceField source="firstCategoryRef" reference="SummaryMeta">
            <ChipField source="title" />
          </ReferenceField>

          <ReferenceArrayField reference="SummaryMeta" source="topicsRef">
            <SingleFieldList>
              <ChipField source="title" />
            </SingleFieldList>
          </ReferenceArrayField>
          <ShowButton />
          <EditButton />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

export const MetaEdit = props => (
  <Edit {...props}>
    <SimpleForm validate={validateMetaFields}>
      <TextInput source="title" />
    </SimpleForm>
  </Edit>
);

export const MetaCreate = props => (
  <Create {...props}>
    <SimpleForm validate={validateMetaFields}>
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          if (formData.type == "ReadingLevel") {
            return (
              <SelectInput
                source="title"
                optionText="id"
                choices={[{ id: "بسيط" }, { id: "متوسط" }, { id: "متقدم" }]}
              />
            );
          } else {
            return <TextInput source="title" />;
          }
        }}
      </FormDataConsumer>

      <SelectInput
        source="type"
        choices={[
          { id: "Category", name: "تصنيف" },
          { id: "Topic", name: "موضوع" },
          { id: "Author", name: "مؤلف" },
          { id: "ReadingLevel", name: "مستوي القراءة" }
        ]}
      />
    </SimpleForm>
  </Create>
);
