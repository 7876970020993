const APP_ID = "gf7n6c97NwulXi9BbD1XrgFcBEBu4FP1m2DDVCGQ";
const JAVASCRIPT_KEY = "C8h4ERCTSeuKRRCN2D1ACBbVCa1DhwK8zoCdDasA";
const MASTER_KEY = "5t0oVM0YLM9h0J4yzYUC8PWhjGtaa3sMHkFiC5CR";
const SERVER_URL = "https://parseapi.back4app.com";
const LOCAL_SERVER_URL = "http://localhost:1337/parse"

var Parse = require("parse");

Parse.initialize(APP_ID, JAVASCRIPT_KEY, MASTER_KEY);
Parse.serverURL = SERVER_URL;
Parse.masterKey = MASTER_KEY

export default Parse