import React from "react";
import {
  TextInput,
  ReferenceInput,
  AutocompleteArrayInput,
  SelectInput,
  ReferenceArrayInput,
  AutocompleteInput,
  TabbedForm,
  FormDataConsumer,
  FormTab,
  Toolbar,
  SaveButton,
  EditButton,
  DeleteButton
} from "react-admin";
import {
  CreateRelatedQuestionButton,
  ShowRelatedQuestionsButton,
  CreateRelatedHighlightButton,
  ShowRelatedHighlightButton
} from "./buttons";
import { getCategories } from "../../parse/meta";

import { getTitle, getSummaryMeta } from "./utils";
import { SimpleForm } from "ra-ui-materialui/lib/form";

const validateSummaryFields = values => {
  const filteredKeys = [
    "title",
    "subtitle",
    "firstCategoryRef",
    "secondCategoryRef",
    "topicsRef",
    "authorRef",
    "summaryDescription",
    "originalFile",
    "conclusion",
    "imageURL",
    "readingLevelRef"
  ].filter(key => values[key] == null);
  const errors = filteredKeys.reduce((errors, currentKey) => {
    
    errors[currentKey] = ["هذه الخانة إلزامية"];
    return errors;
  }, {});
  return errors;
};

const summariesWriters = [
  { id: "Yspuwmly5D", name: "اميرة" },
  { id: "LesquZRatn", name: "ابو العطا" },
  { id: "qDwofq1cAp", name: "نرمين" },
  { id: "zukoQVnAKH", name: "وكيل" }
];

const SummaryEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const SummaryEditableFieldsForWriters = ({ permissions }) => (
  <SimpleForm toolbar={<SummaryEditToolbar />}>
    <TextInput source="title" disabled />
    <SelectInput
      source="summaryWriter"
      optionText="name"
      choices={summariesWriters}
    />

    <CreateRelatedQuestionButton />
    <ShowRelatedQuestionsButton />
    {permissions === "editorAssistant" && (
      <React.Fragment>
        <TextInput multiline source="originalFile" />
      </React.Fragment>
    )}
  </SimpleForm>
);

const summaryDefaultValues = { isFreeSummary: false, isPublished: false };
export const SummaryEditableFields = () => (
  <TabbedForm
    validate={validateSummaryFields}
    defaultValue={summaryDefaultValues}
    redirect="show"
  >
    <FormTab label="محتوي الملخص">
      <CreateRelatedQuestionButton />
      <ShowRelatedQuestionsButton />

      <CreateRelatedHighlightButton />
      <ShowRelatedHighlightButton />

      <TextInput source="title" />
      <TextInput source="subtitle" />
      <TextInput source="imageURL" />
      <TextInput multiline fullWidth source="summaryDescription" />
      <TextInput multiline fullWidth source="conclusion" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          return (
            <TextInput fullWidth multiline
              source="originalFile"
              label="محتوي الملخص"
              onChange={() => {
                const originalFile = formData.originalFile;
                if (originalFile && originalFile.length) {
                  const {
                    title,
                    subtitle,
                    conclusion,
                    intro,
                    imageURL
                  } = getSummaryMeta(originalFile);

                  if (!formData.title || formData.title.length === 0) {
                    formData.title = title;
                  }
                  if (!formData.subtitle || formData.subtitle.length === 0) {
                    formData.subtitle = subtitle;
                  }
                  if (
                    !formData.conclusion ||
                    formData.conclusion.length === 0
                  ) {
                    formData.conclusion = conclusion;
                  }
                  if (
                    !formData.summaryDescription ||
                    formData.summaryDescription.length === 0
                  ) {
                    formData.summaryDescription = intro;
                  }
                  if (!formData.imageURL || formData.imageURL.length === 0) {
                    formData.imageURL = imageURL;
                  }
                }
              }}
            />
          );
        }}
      </FormDataConsumer>
    </FormTab>
    <FormTab label="تفاصيل الملخص">
      <ReferenceInput
        source="readingLevelRef"
        reference="SummaryMeta"
        filter={{ type: "ReadingLevel" }}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>

      <ReferenceInput
        source="authorRef"
        reference="SummaryMeta"
        filter={{ type: "Author" }}
      >
        <AutocompleteInput optionText="title" />
      </ReferenceInput>

      <ReferenceInput
        source="firstCategoryRef"
        reference="SummaryMeta"
        filter={{ type: "Category" }}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>

      <ReferenceInput
        source="secondCategoryRef"
        reference="SummaryMeta"
        filter={{ type: "Category" }}
      >
        <SelectInput optionText="title" />
      </ReferenceInput>

      <ReferenceArrayInput
        reference="SummaryMeta"
        source="topicsRef"
        perPage={100}
        filter={{ type: "Topic" }}
      >
        <AutocompleteArrayInput optionText="title" resettable />
      </ReferenceArrayInput>
    </FormTab>
  </TabbedForm>
);
