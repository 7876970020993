import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";

import moment from "moment";

import { deleteGoal } from "../actions";
import { SetGoalsButtons } from "./setGoalsButtons";

const dateFormat = "YYYY-MM-DD";

const DashboardCard = (props) => {
  const { permissions, title, id, goUp } = props;

  const havePermissionToShowGoals = permissions != "writer";
  const doHaveGoal =
    props.target != null && JSON.stringify(props.target) !== JSON.stringify({});

  let target;
  let deadline;
  let deadlineDate;
  let remainingDays;

  if (doHaveGoal) {
    target = props.target.target;
    deadline = props.target.deadline;

    deadlineDate = moment(deadline, dateFormat);
    remainingDays = deadlineDate.diff(moment(), "days");
  }

  const currentValue = props.current ? props.current.value : 0;
  let oldValue = props.latestLog[id];

  const total = props.summariesCount;
  const progress = currentValue - oldValue;
  const progressTextColor = progress !== 0 ? "green" : null;

  return (
    <Card
      style={{
        width: 150,
        marginRight: 10,
        marginBottom: 10,
      }}
    >
      <CardContent>
        <div
          style={{
            height: 180,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {doHaveGoal && havePermissionToShowGoals && (
            <Badge
              badgeContent={remainingDays}
              color="primary"
              style={{ position: "absolute" }}
            />
          )}

          <Typography
            color="textPrimary"
            align="center"
            style={{ marginBottom: 5, height: 50, paddingTop: 20 }}
          >
            {title}
          </Typography>

          <Fragment>
            <Typography
              color="textPrimary"
              align="center"
              variant="h4"
              gutterBottom
            >
              {currentValue}
            </Typography>

            <Typography align="center" style={{ marginTop: 5, color: "green" }}>
              باقي {goUp == false ? currentValue : total - currentValue} ملخص
            </Typography>
          </Fragment>

          {permissions != "writer" && (
            <Typography
              color="textSecondary"
              align="center"
              style={{ marginTop: 5, color: progressTextColor }}
            >
              منذ اخر تحديث {progress}
            </Typography>
          )}
        </div>
        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        {permissions == "admin" && (
          <SetGoalsButtons deadline={deadline} target={target} deleteGoal={deleteGoal} {...props} />
        )}
        {doHaveGoal && havePermissionToShowGoals && (
          <Fragment>
            <Typography
              color="textSecondary"
              align="center"
              style={{ marginTop: 10 }}
            >
              {currentValue} / {target}
            </Typography>
            {target > currentValue && (
              <Typography color="textSecondary" align="center">
                تم تحقيق %{parseInt((currentValue / target) * 100)}
              </Typography>
            )}
            <Typography
              color="textPrimary"
              align="center"
              variant="H2"
              gutterBottom
              style={{ marginTop: 10 }}
            >
              متبقي {Math.abs(target - currentValue)} ملخص
            </Typography>

            <Typography
              color="textSecondary"
              align="center"
              style={{ marginBottom: 5 }}
            >
              باقي علي موعد التسليم {remainingDays} ايام، بتاريخ{" "}
              {deadlineDate.format(dateFormat)}
            </Typography>
          </Fragment>
        )}
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state, props) => ({
  target: state.data.target ? state.data.target[props.id] : {},
  current: state.data[props.id],
  latestLog: state.data.latestLog || {},
  summariesCount: state.data.summariesCount,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCard);
