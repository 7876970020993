import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";

import ButtonWithAlertDialog from "./components/buttonWithAlertDialog";

import { List } from "@material-ui/core";
import moment from "moment";

import DashboardCard from "./components/dashboardCard";
import DashboardSection from "./components/dashboardSection";

import { LogClass, LogsKeys, statisticsMeta } from "./utils/constants";
import {
  getTotalSummariesCount,
  getConfigData,
  saveConfigData,
  getLatestLog,
} from "./actions";
import AppOfferSection from "./components/appOfferWidget";
import {
  TopicsRelatedActionsSection,
  CategoriesRelatedActionsSection,
  QuizzesRelatedActionsSection,
  AuthorsRelatedActionsSection,
} from "./components/actionsListView";

const showContent = (content) => {
  alert(JSON.stringify(content));
};

export const showDone = () => {
  alert("Done");
};

const Dashboard = ({permissions, ...props}) => {

  if(permissions == 'guest') {
    return null
  }
  const { getTotalSummariesCount, getConfigData, getLatestLog } = props;

  const logCurrentStatistics = async () => {
    const logEntry = new LogClass();

    let current = props.current;
    Object.keys(current).forEach((id) => {
      logEntry.set(id, current[id].currentValue);
    });

    logEntry.save().then(showDone);
  };

  useEffect(() => {
    statisticsMeta.forEach((item) => {
      item.getData(props.dispatch, {
        id: item.id,
        title: item.title,
        goUp: item.goUp,
      });

    });

    getTotalSummariesCount();
    getConfigData();
    getLatestLog();
  }, []);

  return (
    <div>
      
      
      <DashboardSection title="احصائيات عن الملخصات">
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {statisticsMeta.map((data) => (
            <DashboardCard
              title={data.title}
              id={data.id}
              logKey={data.id}
              getData={data.getData}
              goUp={data.goUp}
              permissions={props.permissions}
              onUpdateGoalPress={async ({
                id,
                goalDeadline,
                goalTargetNumber,
              }) => {
                const newGoals = {
                  [id]: {
                    target: goalTargetNumber,
                    deadline: goalDeadline,
                  },
                };
                saveConfigData(newGoals);
                setTimeout(() => {
                  getConfigData();
                }, 3000);
                // cloud code is not returning here
              }}
            />
          ))}
        </div>
        {permissions === "admin" && (
          <ButtonWithAlertDialog
            okButtonTitle="ok"
            cancelButtonTitle="cancel"
            buttonTitle="Log current stastics to db"
            title="are you sure ?"
            okButtonHandler={() => {
              logCurrentStatistics().then(showDone);
            }}
          />
        )}
        <Typography color="textPrimary" variant="caption">
          {`اخر تحديث منذ ${moment().diff(
            moment(props.latestLog.createdAt), // created at
            "days"
          )} ايام ${
            moment().diff(
              moment(props.latestLog.createdAt), // created at
              "hours"
            ) % 24
          } ساعات`}
        </Typography>
      </DashboardSection>

      {permissions == "admin" && (
        <Fragment>
          <List>
            <CategoriesRelatedActionsSection />
            <TopicsRelatedActionsSection />
            <AuthorsRelatedActionsSection />
            <QuizzesRelatedActionsSection />
          </List>

          <AppOfferSection />
        </Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  summariesCount: state.data.summariesCount,
  latestLog: state.data.latestLog || {},
  current: state.data.current,
});

const mapDispatchToProps = (dispatch) => ({
  getTotalSummariesCount: () => getTotalSummariesCount(dispatch),
  getConfigData: () => getConfigData(dispatch),
  getLatestLog: () => getLatestLog(dispatch),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
