import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Typography from "@material-ui/core/Typography";
import { DefaultButton } from "./defaultButton";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Switch,
  TextareaAutosize,
  TextField,
  Grid,
  Box,
  Button,
  Divider,
} from "@material-ui/core";

import moment from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { getSubscriptionCurrentOfferSelector } from "../selectors";
import { updateSubscriptionOffer, getSavedOffers, saveOffer } from "../actions";

import SavedOffersWidget from "./savedOffersWidget";

const AppOfferWidget = (props) => {
  const [offerDetails, setOfferDetails] = useState({});

  useEffect(() => {
    props.getSavedOffers();
  }, []);
  useEffect(() => {
    setOfferDetails(props.offerDetails);
  }, [props.offerDetails]);

  const handleUpdates = (key, value) => {
    setOfferDetails((offerDetails) => ({ ...offerDetails, [key]: value }));
  };

  const onUpdatePress = () => {
    props.updateSubscriptionOffer(offerDetails);
  };

  const onSavePress = () => {
    props.saveOffer(offerDetails);
  };

  const [expanded, setExpanded] = useState(true);
  return (
    <Box
      style={{
        backgroundColor: "white",
        padding: 20,
        marginBottom: 15,
        marginTop: 15,
      }}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        style={{ direction: "rtl" }}
      >
        <Typography variant="h5">عروض الاشتراك</Typography>
        <Button onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </Button>
      </Grid>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <SavedOffersWidget />
        <Typography variant="h5" style={{ marginBottom: 20 }}>
          لائحة تعديل العروض
        </Typography>
        <Divider />
        <MuiPickersUtilsProvider utils={moment}>
          <List>
            {Object.keys(offerDetails).map((key) => {
              switch (typeof offerDetails[key]) {
                case "string":
                case "number":
                  return (
                    <ListItem divider>
                      <ListItemText primary={key} />
                      {(() => {
                        if (key === "actionButtonPrice") {
                          return (
                            <TextField
                              value={offerDetails[key]}
                              onChange={(event) =>
                                handleUpdates(key, event.target.value)
                              }
                              select
                            >
                              <option value={"${localizedPrice}"}>
                                localized price
                              </option>
                              <option value={"${introductoryPrice}"}>
                                introductory price
                              </option>
                            </TextField>
                          );
                        } else if (key === "content") {
                          return (
                            <TextareaAutosize
                              value={offerDetails[key]}
                              onChange={(event) =>
                                handleUpdates(key, event.target.value)
                              }
                            />
                          );
                        } else {
                          return (
                            <TextField
                              value={offerDetails[key]}
                              onChange={(event) =>
                                handleUpdates(key, event.target.value)
                              }
                            />
                          );
                        }
                      })()}
                    </ListItem>
                  );
                case "object":
                  return (
                    <ListItem divider>
                      <ListItemText primary={key} />
                      <DatePicker
                        value={new Date(offerDetails[key])}
                        onChange={(event) =>
                          handleUpdates(key, event.target.value.toString())
                        }
                      />
                    </ListItem>
                  );
                case "boolean":
                  return (
                    <ListItem divider>
                      <ListItemText primary={key} />
                      <Switch
                        checked={offerDetails[key]}
                        onChange={(event) =>
                          handleUpdates(key, event.target.checked)
                        }
                      />
                    </ListItem>
                  );

                default:
                  return (
                    <ListItem divider>
                      <ListItemText primary={key} />
                      <Typography>nothing to show</Typography>
                    </ListItem>
                  );
              }
            })}
          </List>

          <DefaultButton onClick={onUpdatePress}>
            Update Offer Details
          </DefaultButton>
          <DefaultButton onClick={onSavePress}>
            Save Offer For Later
          </DefaultButton>
        </MuiPickersUtilsProvider>
      </Collapse>
    </Box>
  );
};

const mapStateToProps = (state, props) => ({
  offerDetails: getSubscriptionCurrentOfferSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSubscriptionOffer: (offerDetails) =>
    updateSubscriptionOffer(dispatch, offerDetails),
  saveOffer: (offerDetails) => saveOffer(dispatch, offerDetails),
  getSavedOffers: () => getSavedOffers(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppOfferWidget);
