import Parse from "../parse/app";
import { LogClass } from "./utils/constants";

export const TYPES = {
  GET_LATEST_LOG: "GET_LATEST_LOG",
  GET_SUMMARIES_WITH_TOPICS_COUNT: "GET_SUMMARIES_WITH_TOPICS_COUNT",
  GET_SUMMARIES_WITH_AUTHOR_COUNT: "GET_SUMMARIES_WITH_AUTHOR_COUNT",
  GET_SUMMARIES_WITH_QUIZ_COUNT: "GET_SUMMARIES_WITH_QUIZ_COUNT",
  GET_SUMMARIES_WITH_QUIZ_COUNT_THE_COMMON_WAY:
    "GET_SUMMARIES_WITH_QUIZ_COUNT_THE_COMMON_WAY",
  GET_SUMMARIES_WITH_HIGHLIGHTS_COUNT: "GET_SUMMARIES_WITH_HIGHLIGHTS_COUNT",
  GET_SUMMARIES_WITH_RATE_COUNT: "GET_SUMMARIES_WITH_RATE_COUNT",
  GET_TOTAL_SUMMARIES_COUNT: "GET_TOTAL_SUMMARIES_COUNT",
  GET_PUBLISHED_SUMMARIES_COUNT: "GET_PUBLISHED_SUMMARIES_COUNT",
  GET_BAD_SUBTITLED_SUMMARIES_COUNT: "GET_BAD_SUBTITLED_SUMMARIES_COUNT",
  GET_BAD_CONCLUSION_SUMMARIES_COUNT: "GET_BAD_CONCLUSION_SUMMARIES_COUNT",
  GET_CONFIG_DATA: "GET_CONFIG_DATA",
  SAVE_CONFIG_DATA: "SAVE_CONFIG_DATA",
  DELETE_GOAL: "DELETE_GOAL",
  UPDATE_SUBSCRIPTION_OFFER: "UPDATE_SUBSCRIPTION_OFFER",
  SAVE_OFFER: "SAVE_OFFER",
  GET_SAVED_OFFERS: "GET_SAVED_OFFERS",
  SET_AS_CURRENT_OFFER: "SET_AS_CURRENT_OFFER",
  DELETE_OFFER: "DELETE_OFFER",
};

export async function getSummariesWithTopicsCount(dispatch, options) {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query.exists("topicsRef");
  const summariesWithTopicsCount = await query.count();

  return dispatch({
    type: TYPES.GET_SUMMARIES_WITH_TOPICS_COUNT,
    payload: { value: summariesWithTopicsCount, ...options },
  });
}
export async function getSummariesWithAuthorCount(dispatch, options) {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query.exists("authorRef");
  const summariesWithAuthorCount = await query.count();

  return dispatch({
    type: TYPES.GET_SUMMARIES_WITH_AUTHOR_COUNT,
    payload: { value: summariesWithAuthorCount, ...options },
  });
}
export async function getSummariesWithQuizCount(dispatch, options) {
  const query = new Parse.Query(Parse.Object.extend("SummaryQuiz"));
  var pipelineObject = {
    group: { objectId: "$summaryRef" },
  };
  const summariesWithQuizCount = await query
    .aggregate(pipelineObject)
    .then((results) => results.length);
  return dispatch({
    type: TYPES.GET_SUMMARIES_WITH_QUIZ_COUNT,
    payload: { value: summariesWithQuizCount, ...options },
  });
}

export async function getSummariesWithQuizCountTheCommonWay(dispatch, options) {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query.greaterThan("quizzesCount", 0);
  const count = await query.count();

  return dispatch({
    type: TYPES.GET_SUMMARIES_WITH_QUIZ_COUNT_THE_COMMON_WAY,
    payload: { value: count, ...options },
  });
}
export async function getSummariesWithHighlightsCount(dispatch, options) {
  const query = new Parse.Query(Parse.Object.extend("Highlight"));
  var pipelineObject = {
    group: { objectId: "$summaryRef" },
  };
  const summariesWithHighlightsCount = await query
    .aggregate(pipelineObject)
    .then((results) => results.length);

  return dispatch({
    type: TYPES.GET_SUMMARIES_WITH_HIGHLIGHTS_COUNT,
    payload: { value: summariesWithHighlightsCount, ...options },
  });
}
export async function getSummariesWithRateCount(dispatch, options) {
  const query = new Parse.Query(Parse.Object.extend("LibraryNew"));
  query.exists("userRate");
  const summariesWithRateCount = await query.count();

  return dispatch({
    type: TYPES.GET_SUMMARIES_WITH_RATE_COUNT,
    payload: { value: summariesWithRateCount, ...options },
  });
}

export async function getPublishedSummariesCount(dispatch, options) {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));
  //query.equalTo("isPublished", true);
  const publishedSummariesCount = await query.count();

  return dispatch({
    type: TYPES.GET_PUBLISHED_SUMMARIES_COUNT,
    payload: { value: publishedSummariesCount, ...options },
  });
}
export async function getBadSubtitledSummariesCount(dispatch, options) {
  const query1 = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query1.equalTo("subtitle", "***");
  const query2 = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query2.equalTo("subtitle", "");
  const query3 = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query3.doesNotExist("subtitle");
  const badSubtitledSummariesCount = await Parse.Query.or(
    query1,
    query2,
    query3
  ).count();

  return dispatch({
    type: TYPES.GET_BAD_SUBTITLED_SUMMARIES_COUNT,
    payload: { value: badSubtitledSummariesCount, ...options },
  });
}

export async function getBadConclusionSummariesCount(dispatch, options) {
  const query1 = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query1.equalTo("conclusion", "***");
  const query2 = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query2.equalTo("conclusion", "");
  const query3 = new Parse.Query(Parse.Object.extend("SummaryNew"));
  query3.doesNotExist("conclusion");
  const badConclusionSummariesCount = await Parse.Query.or(
    query1,
    query2,
    query3
  ).count();

  return dispatch({
    type: TYPES.GET_BAD_CONCLUSION_SUMMARIES_COUNT,
    payload: { value: badConclusionSummariesCount, ...options },
  });
}

export const getConfigData = async (dispatch) => {
  const config = await Parse.Config.get();

  return dispatch({
    type: TYPES.GET_CONFIG_DATA,
    payload: config.attributes,
  });
};

export const saveConfigData = async (dispatch, props) => {
  const data = await Parse.Cloud.run("changeConfig", props);

  return dispatch({
    type: TYPES.SAVE_CONFIG_DATA,
    payload: data,
  });
};

export const updateSubscriptionOffer = async (dispatch, offerDetails) => {
  await Parse.Cloud.run("changeConfig", { subscriptionOffer: offerDetails });
  return dispatch({
    type: TYPES.UPDATE_SUBSCRIPTION_OFFER,
    payload: offerDetails,
  });
};

export const deleteGoal = async (dispatch, goalId) => {
  const data = await Parse.Cloud.run("changeConfig", { [goalId]: {} });
  return dispatch({
    type: TYPES.DELETE_GOAL,
    payload: data,
  });
};

export const saveOffer = async (dispatch, offerDetails) => {
  const logEntry = new LogClass();
  logEntry.set("type", "offer");
  logEntry.set("data", offerDetails);

  const savedLogEntry = await logEntry.save();

  dispatch({
    type: TYPES.SAVE_OFFER,
    payload: { ...offerDetails, id: savedLogEntry.id },
  });
};

export const getSavedOffers = async (dispatch) => {
  const query = new Parse.Query(LogClass);

  query.equalTo("type", "offer");
  const response = await query.find();

  const offers = response.map((item) => {
    const data = item.get("data");
    const id = item.id;

    return {
      id,
      ...data,
    };
  });

  return dispatch({
    type: TYPES.GET_SAVED_OFFERS,
    payload: offers,
  });
};

export async function getTotalSummariesCount(dispatch) {
  const query = new Parse.Query(Parse.Object.extend("SummaryNew"));
  const totalSummariesCount = await query.count();
  return dispatch({
    type: TYPES.GET_TOTAL_SUMMARIES_COUNT,
    payload: totalSummariesCount,
  });
}

export async function getLatestLog(dispatch) {
  let query = new Parse.Query(LogClass);
  query.descending("updatedAt");
  query.limit(1);
  let latestLog = await query.first();

  return dispatch({
    type: TYPES.GET_LATEST_LOG,
    payload: latestLog.attributes,
  });
}

export const setAsCurrentOffer = (dispatch, offer) => {
  return dispatch({
    type: TYPES.SET_AS_CURRENT_OFFER,
    payload: offer,
  });
};

export const deleteOffer = async (dispatch, offer) => {
  const offerId = offer.id;
  const logObject = new LogClass();
  logObject.id = offerId;

  await logObject.destroy();

  return dispatch({
    type: TYPES.DELETE_OFFER,
    payload: offerId,
  });
};
