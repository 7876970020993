import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";

import { List } from "@material-ui/core";
import moment from "moment";

import OfferCard from "./offerCard";

import { getSavedOffers } from "../actions";
import { getSubscriptionCurrentOfferSelector } from "../selectors";

const offerSample = {
  title: "خصم 231٪",
  subtitle: "عرض  اشتراك اسبوع العيد",
  content:
    "كل عام و انتم بخير، بمناسبة عيد الفطر المبارك، خصم ٥٠٪ دائم علي الاشتراك السنوي، نتمني ان ينال التطبيق اعجابك و تجده نافعا ان شاء الله",
  actionButtonTitle: "اشتراك سنوي",
  actionButtonPrice: "${localizedPrice}",
  deadlineDate: "06/02/2020",
  hasTrialPeriod: false,
  trialPeriodTitle:
    "بتم التجديد تلقائيا عند نهاية فترة الاشتراك بالسعر السنوي العادي ${localizedPrice}",
  isFirstTimeUserOffer: false,
  daysTillTheOfferEndForFirstTimeUsers: "4",
  showCountDownTimer: true,
  appleSubscriptionID: "YearSubscriptionOffer2",
  androidOfferID: "",
  isActive: true,
};

const SavedOffersWidget = (props) => {
  const { getSavedOffers, savedOffers } = props;

  useEffect(() => {
    getSavedOffers();
  }, []);

  return (
    <div style={{direction: 'rtl', marginTop: 20, marginBottom: 20}}>
      <Typography variant="h5" style={{marginBottom: 10}}>
        العرض الحالي
      </Typography>
      {props.currentOffer && <OfferCard offer={props.currentOffer} isCurrent/>}

      <Typography variant="h5" style={{marginBottom: 10}}>
        العروض المعدة مسبقاً
      </Typography>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {savedOffers && savedOffers.map((data) => <OfferCard offer={data} />)}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  savedOffers: state.data.savedOffers,
  currentOffer: getSubscriptionCurrentOfferSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getSavedOffers: () => getSavedOffers(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedOffersWidget);
