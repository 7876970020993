import Parse from "../../parse/app";
import {
  
  getSummariesWithRateCount,
  getTotalSummariesCount,
  getLatestLog,
  getSummariesWithQuizCount,
  getSummariesWithHighlightsCount,
  getBadConclusionSummariesCount,
  getSummariesWithAuthorCount,
  getBadSubtitledSummariesCount,
  getPublishedSummariesCount,
  getSummariesWithTopicsCount,
  getSummariesWithQuizCountTheCommonWay,
  
} from "../actions";

export const LogClass = new Parse.Object.extend("StatisticsLog");
export const LogsKeys = {
  publishedSummariesCount: "publishedSummariesCount",
  summariesWithQuizCount: "summariesWithQuizCount",
  summariesWithAuthorCount: "summariesWithAuthorCount",
  badConclusionSummariesCount: "badConclusionSummariesCount",
  summariesWithTopicsCount: "summariesWithTopicsCount",
  badSubtitledSummariesCount: "badSubtitledSummariesCount",
  summariesWithHighlightsCount: "summariesWithHighlightsCount",
  totalSummariesCount: "totalSummariesCount",
  summariesWithRateCount: "summariesWithRateCount",
  getSummariesWithQuizCountTheCommonWay: "summariesWithQuizCountCommonWay"
};

export const statisticsMeta = [
  {
    id: LogsKeys.publishedSummariesCount,
    title: "تم رفعها",
    getData: getPublishedSummariesCount,
    goUp: true
  },
  {
    title: "ملخصات لديها موضوعات",
    id: LogsKeys.summariesWithTopicsCount,
    getData: getSummariesWithTopicsCount,
    goUp: true
  },
  {
    title: "ملخصات لديها اسم مؤلف",
    id: LogsKeys.summariesWithAuthorCount,
    getData: getSummariesWithAuthorCount,
    goUp: true
  },
  {
    title: "عنوان فرعي خاطئ او مفقود",
    id: LogsKeys.badSubtitledSummariesCount,
    getData: getBadSubtitledSummariesCount,
    goUp: false
  },
  {
    title: "محتوي للخاتمة خاطئ او مفقود",
    id: LogsKeys.badConclusionSummariesCount,
    getData: getBadConclusionSummariesCount,
    goUp: false
  },
  // {
  //   title: "ملخصات لديها تقييم",
  //   id: LogsKeys.summariesWithRateCount,
  //   getData: getSummariesWithRateCount
  // },
  {
    title: "ملخصات لديها مقتطفات",
    id: LogsKeys.summariesWithHighlightsCount,
    getData: getSummariesWithHighlightsCount,
    goUp: true
  },
  {
    title: "ملخصات لديها اسئلة",
    id: LogsKeys.summariesWithQuizCount,
    getData: getSummariesWithQuizCount,
    goUp: true
  },{
    title: "ملخصات لديها اسئلة - تحديث غير فوري",
    id: LogsKeys.getSummariesWithQuizCountTheCommonWay,
    getData: getSummariesWithQuizCountTheCommonWay,
    goUp: true
  }
];
