import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

export default ({title, ...props}) => {
  return (
    <Card style={{marginBottom: 15}}>
        <Typography style={{margin: 20}} color="textPrimary" variant="h4" align='right'>
            {title}
          </Typography>
          <Divider />
      <CardContent>{props.children}</CardContent>
    </Card>
  );
};
