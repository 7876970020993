import React from "react";
import {
  Admin,
  Resource,
  EditGuesser,
  ListGuesser,
  RouteWithoutLayout,
} from "react-admin";
import { UserList } from "./models/user";
import {
  SummaryList,
  SummaryEdit,
  SummaryCreate,
  SummaryShow,
} from "./models/summary/summaries";
import { MetaList, MetaEdit, MetaCreate, MetaShow } from "./models/meta";
import {
  HighlightList,
  HighlightCreate,
  HighlightEdit,
} from "./models/highlight";
import QuizList, { QuizEdit, QuizCreate } from "./models/quiz/quiz";
import reducer from "./dashboard/reducer";

import Dashboard from "./dashboard/dashboard";

import dataProvider from "./parse/dataProvider";
import authProvider from "./parse/authProvider";
import jsonServerProvider from "ra-data-json-server";
import arabicMessages from "./dashboard/utils/arabicTranslation";
import polyglotI18nProvider from "ra-i18n-polyglot";
import ArticleOfSummariesIdsPage from "./pages/articleOfSummariesIdsPage";

const i18nProvider = polyglotI18nProvider(() => arabicMessages, "ar");
//const i18nProvider = () => arabicMessages;

const dataProvider2 = jsonServerProvider(
  "https://jsonplaceholder.typicode.com"
);
const App2 = () => <Admin dataProvider={dataProvider2} />;

const App = (props) => {
  return (
    <Admin
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      locale="ar"
      i18nProvider={i18nProvider}
      customReducers={{ data: reducer }}
      customRoutes={[
        <RouteWithoutLayout
          path="/summariesIdsArticle"
          component={ArticleOfSummariesIdsPage}
        />,
      ]}
    >
      {(permissions) => {
        return permissions == "guest"
          ? [
              <Resource name="SummaryQuiz" list={QuizList} />,
              <Resource name="SummaryMeta" list={MetaList} />,
            ]
          : [
              <Resource
                name="SummaryNew"
                show={SummaryShow}
                list={SummaryList}
                edit={SummaryEdit}
                create={SummaryCreate}
              />,
              <Resource
                name="SummaryMeta"
                list={MetaList}
                edit={MetaEdit}
                create={MetaCreate}
                show={MetaShow}
              />,
              permissions !== "Anyone" ? (
                <Resource
                  name="Highlight"
                  list={HighlightList}
                  create={HighlightCreate}
                  edit={HighlightEdit}
                />
              ) : null,
              <Resource
                name="SummaryQuiz"
                list={QuizList}
                edit={QuizEdit}
                create={QuizCreate}
              />,
              <Resource name="User" />,
            ];
      }}
    </Admin>
  );
};

export default App;
