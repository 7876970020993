import { TYPES } from "./actions";

export default (state = {}, { type, payload }) => {
  switch (type) {
    case TYPES.GET_PUBLISHED_SUMMARIES_COUNT:
    case TYPES.GET_BAD_CONCLUSION_SUMMARIES_COUNT:
    case TYPES.GET_BAD_SUBTITLED_SUMMARIES_COUNT:
    case TYPES.GET_SUMMARIES_WITH_AUTHOR_COUNT:
    case TYPES.GET_SUMMARIES_WITH_QUIZ_COUNT:
    case TYPES.GET_SUMMARIES_WITH_HIGHLIGHTS_COUNT:
    case TYPES.GET_SUMMARIES_WITH_QUIZ_COUNT_THE_COMMON_WAY:
    case TYPES.GET_SUMMARIES_WITH_RATE_COUNT:
    case TYPES.GET_SUMMARIES_WITH_TOPICS_COUNT:
      const { id, ...data } = payload;
      return { ...state, [id]: data };
    case TYPES.GET_CONFIG_DATA:
      return { ...state, config: payload };
    case TYPES.UPDATE_SUBSCRIPTION_OFFER:
    case TYPES.SET_AS_CURRENT_OFFER:
      return {
        ...state,
        config: { ...state.config, subscriptionOffer: payload },
      };
    case TYPES.GET_TOTAL_SUMMARIES_COUNT:
      return { ...state, summariesCount: payload };
    case TYPES.GET_LATEST_LOG:
      return { ...state, latestLog: payload };
    case TYPES.GET_SAVED_OFFERS:
      return { ...state, savedOffers: payload };
    case TYPES.SAVE_OFFER: {
      const savedOffers = state.savedOffers;
      savedOffers.push(payload);
      return { ...state, savedOffers };
    }
    case TYPES.DELETE_OFFER: {
      const toDeleteOfferId = payload;
      const savedOffers = state.savedOffers.filter(
        (offer) => offer.id !== toDeleteOfferId
      );
      return { ...state, savedOffers };
    }
    default:
      return state;
  }
};
